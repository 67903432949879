import React, { useState, useEffect, useMemo} from 'react';
import { Link } from 'react-router-dom';

import Section from "components/app/school/SmallerComponents/Section";
import Mallet from 'components/app/school/SmallerComponents/mallet';
import View from 'components/app/school/SmallerComponents/mallet/view';

import { numberDigit } from 'components/app/school/actions/numberDigit';
import { appServices } from 'api/schoolApp/services';


const StudentProfileContent = ({data, uid, school_id, school_title}) => {
    const initialState = useMemo(() => ({id:0, class_name:'', is_self_sponsored:false, sess_added:{id:0, term:{id:0}},  user:{id:0, profile_pic:null}, country_origin:{id:0}, guardian:{id:0}, current_class:{id:0}, fath_occupation:{id:0}, emergency_country_res:{id:0}, emergency_type:{id:0}, guardian_type:{id:0}, state_res:{id:0}, report_card:null, birth_cert:null, profile_pic:null}), []);
    const [state, setState] = useState(initialState);
    const [allSubject, setAllSubject] = useState([]);

    const fetchAllSubject = (uid="", sid=0) => {
        if(uid && sid){
            appServices.getAllStudentSubject_Admin(uid, sid).then(res => {
                setAllSubject(res.data);
            }).catch(e => {
                setAllSubject([]);
            })
        }
    }

    useEffect(() => {
        setState(data);
    }, [data]);
    useEffect(() => {
        fetchAllSubject(uid, school_id);
    }, [school_id, uid]);

    // console.log(state)

    const statBtn = (stat={is_parent_access_app:false, is_setup_app_account:false, is_cs_paid:false, is_assigned_subjects:false}) => {
        return(
            <>
                <span className='disp-block fw-500 text-capitalize app-text-color font-super-small' style={{paddingBottom:'2px'}}>Current Term Payment  <span className={`fw-700 ${stat.is_cs_paid ? 'fas fa-check green' : 'fas fa-times red'}`}></span> </span>
                <span className='disp-block fw-500 text-capitalize app-text-color font-super-small' style={{paddingBottom:'2px'}}> kid's school app setup <span className={`fw-700 ${stat.is_setup_app_account ? 'fas fa-check green' : 'fas fa-times red'}`}></span> </span>
                <span className='disp-block fw-500 text-capitalize app-text-color font-super-small' style={{paddingBottom:'2px'}}> Completed subject to offer in school  <span className={`fw-700 ${stat.is_assigned_subjects ? 'fas fa-check green' : 'fas fa-times red'}`}></span> </span>
                <span className='disp-block fw-500 text-capitalize app-text-color font-super-small' style={{paddingBottom:'2px'}}> parent's access to kid's school app <span className={`fw-700 ${stat.is_parent_access_app ? 'fas fa-check green' : 'fas fa-times red'}`}></span> </span>
            </>
        )
    }
    const child_custody = state.is_both_custody ? 'Guardian/Parent' : state.is_father_custody ? 'Father' : state.is_mother_custody ? 'Mother' : ''
    return(
        <Section>
            <span className="font-med fw-700 disp-block app-text-color">Student's Profile</span><br />
            <span className="font-small fw-700 disp-block padd-4px app-text-color">School Information</span>
            <Mallet>
                <View flex='33' type="text" label="ADM No.">{numberDigit(state.adm_no, 4)}</View>
                <View flex='33' type="text" label="Class.">{state.class_name && state.class_name.toUpperCase()}</View>
                <View flex='33' type="text" label="House.">{state.house_name ? state.house_name : state.house_color_name}</View>
                <View flex='100' type="text" label="Status.">{statBtn({is_assigned_subjects:state.is_assigned_subjects, is_setup_app_account:state.is_setup_app_account, is_cs_paid:state.is_cs_paid,is_parent_access_app:state.is_parent_access_app})}</View> 
                <View flex='100' type="text" label="Subject(s) Offered.">{allSubject.map((item, i) => {
                    return(
                        <span key={i} className='fw-500 text-capitalize app-text-color font-very-small'>{item.subject_name}, </span>
                    )
                })
                }<span className='fw-600 disp-block text-capitalize app-text-color font-very-small' style={{paddingTop:'2px'}}>Total: {allSubject.length} </span>
                <div className='disp-block'>
                    <Link to={`/${school_title}/student/m/${uid}/add_subject`} className='btn br app-bg-color padd-4px app-bg-text-color font-very-small'><i className='fas fa-plus'></i> Add Subject</Link>
                </div>
                </View>
                <View flex='50' type="text" label="Sponsored By.">{state.is_self_sponsored ? 'Self' : 'Parent / Guardian'}</View>
                <View flex='50' type="text" label="Session Joined">{state.sess_added.session} - {state.sess_added.term.term} term</View>
            </Mallet>
            <span className="font-small fw-700 disp-block padd-4px app-text-color">Personal Information</span>
            <Mallet>
                <View flex='50' type="text" label="Gender">{state.user ? state.user.gender : state.gender}</View>
                <View flex='50' type="text" label="Telephone No.">+{state.user ? state.user.phone_number : state.guardian_phone_number}</View>
                <View flex='50' type="text" label="Email.">{state.user ? `${state.user.email}` : state.guardian_email}</View>
                <View flex='50' type="text" label="D.O.B.">{state.dob}</View>
                <View flex='50' type="text" label="Religion.">{state.rel}</View>
                <View flex='50' type="text" classname='text-capitalize' label="Language(s) Spoken.">{state.lang_spoken}</View>
                <View flex='50' type="text" label="Nationality.">{state.country_origin && state.country_origin.name}</View>
                <View flex='50' type="text" label="State Of Residence.">{state.state_res && state.state_res.name}</View>
                <View flex='50' type="text" label="Address.">{state.address}</View>
                <View flex='50' type="text" label="Medical Challenges.">{!state.med_challenge ? 'None' : state.med_challenge}</View>
                {!state.is_self_sponsored && <View flex='50' type="text" label="Child Custody.">{child_custody}</View>}
                <View flex='50' type="text" label="Mother Deceased.">{state.is_mother_deceased ? 'Yes' : 'No'}</View>
                <View flex='50' type="text" label="Father Deceased.">{state.is_father_deceased ? 'Yes' : 'No'}</View>
                
            </Mallet>
            {!state.is_self_sponsored && (
                <>
                {state.guardian && (
                    <>
                    <span className="font-small fw-700 disp-block padd-4px app-text-color">Guardian Information</span>
                    <Mallet>
                        <View flex='50' type="text" classname='text-capitalize' label="Full Name.">{state.guardian.title}. {state.guardian_last_name} {state.guardian_first_name}</View>
                        <View flex='50' type="text" label="Email">{state.guardian_email}</View>
                        <View flex='50' type="text" label="Telephone No.">{state.guardian_phone_number && `+${state.guardian_phone_number}`}</View>
                        <View flex='50' type="text" label="Work Telephone No.">{state.guardian.spouse_phone}</View>
                        <View flex='50' type="text" label="Religion.">{state.guardian.rel}</View>
                        <View flex='50' type="text" label="Marital Status">{state.guardian.marital_status}</View>
                        <View flex='50' type="text" label="Address">{state.guardian.address}</View>
                        <View flex='50' type="text" label="Relationship to child.">{state.first_relationship}</View>
                    </Mallet>
                    </>
                )}
                </>
            )}
            {state.is_self_sponsored && (
                <>
                    <span className="font-small fw-700 disp-block padd-4px app-text-color">Father's Information</span>
                    <Mallet>
                        <View flex='50' type="text" classname='text-capitalize' label="Full Name.">{state.fath_fullname}</View>
                        <View flex='50' type="text" label="Email">{state.fath_email}</View>
                        <View flex='50' type="text" label="Telephone No.">{state.fath_phone}</View>
                        <View flex='50' type="text" label="Work Telephone No.">{state.fath_work_phone}</View>
                        <View flex='50' type="text" label="Address">{state.fath_addr}</View>
                    </Mallet>
                    <span className="font-small fw-700 disp-block padd-4px app-text-color">Mother's Information</span>
                    <Mallet>
                        <View flex='50' type="text" classname='text-capitalize' label="Full Name.">{state.moth_fullname}</View>
                        <View flex='50' type="text" label="Email">{state.moth_email}</View>
                        <View flex='50' type="text" label="Telephone No.">{state.moth_phone}</View>
                        <View flex='50' type="text" label="Work Telephone No.">{state.moth_work_phone}</View>
                        <View flex='50' type="text" label="Address">{state.moth_addr}</View>
                    </Mallet>
                </>
            )}
            <span className="font-small fw-700 disp-block padd-4px app-text-color">Emergency Contact Information</span>
            {state.guardian ? (
                <Mallet>
                    <View flex='50' type="text" classname='text-capitalize' label="Full Name.">{state.guardian.emergency_fullname}</View>
                    <View flex='50' type="text" label="Email">{state.guardian.emergency_email}</View>
                    <View flex='50' type="text" label="Telephone No.">{state.guardian.emergency_phone}</View>
                    <View flex='50' type="text" label="Work Telephone No.">{state.guardian.emergency_work_phone}</View>
                    <View flex='50' type="text" label="Country Residence.">{state.emergency_country_origin_name}</View>
                    <View flex='50' type="text" label="Address">{state.guardian.emergency_addr}</View>
                    <View flex='50' type="text" label="Relationship to child.">{state.emergency_relate_name}</View>
                </Mallet>
            ) : (
                <Mallet>
                    <View flex='50' type="text" classname='text-capitalize' label="Full Name.">{state.emergency_fullname}</View>
                    <View flex='50' type="text" label="Email">{state.emergency_email}</View>
                    <View flex='50' type="text" label="Telephone No.">{state.emergency_phone}</View>
                    <View flex='50' type="text" label="Work Telephone No.">{state.emergency_work_phone}</View>
                    <View flex='50' type="text" label="Country Residence.">{state.emergency_country_res && state.emergency_country_res.name}</View>
                    <View flex='50' type="text" label="Address">{state.emergency_addr}</View>
                    <View flex='50' type="text" label="Relationship to child.">{state.emergency_type && state.emergency_type.name}</View>
                </Mallet>
            )}
        </Section>
    )
}

export default StudentProfileContent;