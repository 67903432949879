import { createGlobalStyle } from 'styled-components';
import { BgColor } from 'actions/school_color';

const schColor = "green";
const schBagColor = "black";
const voBgColor = "#eee";
const voskoolBagColor = "#26223a";
const app_body_color = "#eee";
const app_theme = "#fff";
const app_opp_theme = "#000";
const app_opp_text_color = "#eee";
const app_bg_color = localStorage.getItem('app_bg_color') ? BgColor(localStorage.getItem('app_bg_color')) : '#00057a';
const app_bg_text_color = "#eee";
const app_bg_sec_text_color = "#ddd";
const app_bg_sec_color = "#00f";
const app_nav_text_color = "#173f5f";
const app_text_dark_grey = "#555";
const app_text_light_grey = "#ccc";
const app_text_lighter_grey = "#ddd";
const app_text_color = "#222";
const app_text_sec_color = "#aaa";
const app_shadow_color = "#000000e1";

export const APPCOLORS = {
    app_theme:app_theme,
}
export const SchoolAppStyle = createGlobalStyle`
    :root{
        --appBodyColor: ${app_body_color};
        --appBgColor: ${app_bg_color};
        --appShadowColor: ${app_shadow_color};
        --appTheme:${app_theme};
        --appOppTheme:${app_opp_theme};
        --appOppTextColor:${app_opp_text_color};
        --appBgSecColor:${app_bg_sec_color};
        --appBgTextColor:${app_bg_text_color};
        --appBgSecTextColor:${app_bg_sec_text_color};
        --appBgColorText: ${app_bg_color};
        --appNavTextColor:${app_nav_text_color};
        --appTextColor:${app_text_color};
        --appTextSecColor:${app_text_sec_color};
        --appTextLightGrey:${app_text_light_grey};
        --appTextLighterGrey:${app_text_lighter_grey};
        --appTextDarkGrey:${app_text_dark_grey};
    }
    html{
        scroll-behavior:smooth;
    }
    *{
        box-sizing: border-box;
    }
    ::-webkit-scrollbar {
        width: 0.5rem;
    }

    ::-webkit-scrollbar-track {
     background: var(--appTheme);
    }

    ::-webkit-scrollbar-thumb {
        background: var(--appOppTheme);
    }
    ::-moz-scrollbar {
    width: 0.5rem;
    }

    ::-moz-scrollbar-track {
        background: var(--appTheme);
    }

    ::-moz-scrollbar-thumb {
        background: var(--appOppTheme);
    }
    ::-ms-scrollbar {
        width: 0.5rem;
    }

    ::-ms-scrollbar-track {
        background: var(--appTheme);
    }

    ::-ms-scrollbar-thumb {
        background: var(--appOppTheme);
    }

    body{
        margin: 0;
        padding: 0;
        font-family: "Raleway", sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background: ${app_body_color};
        /* overflow-y: auto;
        @media(max-width:540px){
            overflow-y: scroll;
        } */
        .z-9{
            z-index: 9;
        }
        .z-over{
            z-index: 999999999999999999999999999999;
        }
        .app-body-color{
            background: ${app_body_color};
        }
        .app-shadow-color{
            background: ${app_shadow_color};
        }
        .app-theme{
            background: ${app_theme};
        }
        .app-opp-theme{
            background: ${app_opp_theme};
        }
        .app-opp-text-color{
            color: ${app_opp_text_color};
        }
        .app-bg-color-text{
            color: ${app_bg_color};
        }
        .app-bg-color{
            background: ${app_bg_color};
        }
        .app-bg-text-color{
            color: ${app_bg_text_color};
        }
        .app-bg-sec-text-color{
            color: ${app_bg_sec_text_color};
        }
        .app-bg-sec-color{
            background: ${app_bg_sec_color};
        }
        .app-text-light-grey{
            color: ${app_text_light_grey};
        }
        .app-text-lighter-grey{
            color: ${app_text_lighter_grey};
        }
        .app-nav-text-color{
            color: ${app_nav_text_color};
            letter-spacing: .5px;
            transition: .4s ease-in-out;
            -webkit-transition: .4s ease-in-out;
            :hover{
                background: ${app_bg_color};
                color: #fff;
            }
        }
        .app-text-color{
            color: ${app_text_color}
        }
        .app-text-sec-color{
            color: ${app_text_sec_color}
        }
        .app-layout-grid{
            display: grid;
            grid-template-columns: 3fr 1fr;
            grid-gap: 2rem;

            @media(max-width:760px){
                grid-template-columns: 3fr;
            }
        }
        .flex-100{
            flex: 0 0 100%;
            max-width: 100%;
        }
        .flex-90-line{
            flex: 0 0 90%;
            max-width: 90%;
        }
        .flex-50{
            flex: 0 0 50%;
            max-width: 50%;
            margin-bottom: 9px;
            @media (max-width: 780px){
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
        .flex-5-line{
            flex: 0 0 5%;
            max-width: 5%;
        }
        .flex-70{
            flex: 0 0 70%;
            max-width: 70%;
            margin-bottom: 9px;
            @media (max-width: 780px){
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
        .flex-30{
            flex: 0 0 30%;
            max-width: 30%;
            margin-bottom: 9px;
            @media (max-width: 780px){
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
        .flex-30-line{
            flex: 0 0 30%;
            max-width: 30%;
        }
        .flex-30-m{
            flex: 0 0 30%;
            max-width: 30%;
            margin-bottom: 9px;
            @media (max-width: 1280px){
                flex: 0 0 50%;
                max-width: 50%;
            }
            @media (max-width: 540px){
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
        .flex-50-line{
            flex: 0 0 50%;
            max-width: 50%;
            margin-bottom: 2px;
        }
        
        .center-td-wrapper{
            left: 20%;
            @media(max-width: 1280px){
                left: 20%;
            }
            @media(max-width: 320px){
                left: 18%;
            }
        }
        .green{
            color: #0f0 !important;
        }
        .red{
            color: #f00 !important;
        }
        .font-very-super-small{
            font-size: .5rem !important;
            @media(max-width: 780px){
                font-size: .45rem !important;
            }
            @media(max-width: 540px){
                font-size: .45rem !important;
            }
            @media(max-width: 320px){
                font-size: .45rem !important;
            }
            @media(max-width: 280px){
                font-size: .4rem !important;
            }
        }
        .font-super-small{
            font-size: .7rem;
            @media(max-width: 780px){
                font-size: .65rem;
            }
            @media(max-width: 540px){
                font-size: .6rem;
            }
            @media(max-width: 320px){
                font-size: .5rem;
            }
            @media(max-width: 280px){
                font-size: .45rem;
            }
        }
        .font-very-small{
            font-size: .8rem;
            @media(max-width: 780px){
                font-size: .75rem;
            }
            @media(max-width: 540px){
                font-size: .65rem;
            }
            @media(max-width: 320px){
                font-size: .55rem;
            }
            @media(max-width: 280px){
                font-size: .5rem;
            }
        }
        .font-slightly-small{
            font-size: .85rem;
            @media(max-width: 780px){
                font-size: .75rem;
            }
            @media(max-width: 540px){
                font-size: .7rem;
            }
            @media(max-width: 320px){
                font-size: .6rem;
            }
            @media(max-width: 280px){
                font-size: .5rem;
            }
        }
        .font-small{
            font-size: 1rem;
            @media(max-width: 780px){
                font-size: .8rem;
            }
            @media(max-width: 540px){
                font-size: .75rem;
            }
            @media(max-width: 320px){
                font-size: .6rem;
            }
            @media(max-width: 280px){
                font-size: .55rem;
            }
        }
        .font-med{
            font-size: 1.2rem;

            @media(max-width: 1280px){
                font-size: 1.1rem;
            }
            @media(max-width: 780px){
                font-size: 1rem;
            }
            @media(max-width: 540px){
                font-size: 0.9rem;
            }
            @media(max-width: 320px){
                font-size: .7rem;
            }
            @media(max-width: 280px){
                font-size: .6rem;
            }
        }
        .font-big{
            font-size: 1.5rem;
            @media(max-width: 780px){
                font-size: 1.2rem;
            }
            @media(max-width: 540px){
                font-size: 1.1rem;
            }
            @media(max-width: 370px){
                font-size: 1rem;
            }
            @media(max-width: 320px){
                font-size: .8rem;
            }
            @media(max-width: 280px){
                font-size: .7rem;
            }
            
        }
        .font-bigger{
            font-size: 1.8rem;
            @media(max-width: 780px){
                font-size: 1.5rem;
            }
            @media(max-width: 540px){
                font-size: 1.3rem;
            }
            @media(max-width: 320px){
                font-size: 1.2rem;
            }
            
        }
        .font-super-big{
            font-size: 2.3rem;
            @media(max-width: 780px){
                font-size: 2rem;
            }
            @media(max-width: 540px){
                font-size: 1.8rem;
            }
            @media(max-width: 320px){
                font-size: 1.6rem;
            }
        }
        .form-group{
            display: inline-block;
        }
        .form-control.disabled{
            background: #ccc;
            cursor: not-allowed;
        }
        .form-control-theme{
            width: 100%;
            padding: 5px;
            border-radius: 5px;
            border: 1px solid #888;
            outline: none;
            transition: .3s ease;
            font-weight: 500;
            background: ${app_theme};
            :focus{
                box-shadow: 0 0 3px 5px rgba(0,0,0,0.1);
            }
        }
        .form-control{
            width: 100%;
            padding: 5px;
            border-radius: 5px;
            border: 1px solid #888;
            outline: none;
            transition: .3s ease;
            font-weight: 500;
            background: ${app_body_color};
            :focus{
                box-shadow: 0 0 3px 5px rgba(0,0,0,0.1);
            }
        }
        .empty-option{
            color: #aaa;
        }
        
        .align-center{
            text-align: center;
        }
        .align-center-m{
            text-align: center;
            @media(max-width:540px){
                text-align: left;
            }
        }
        .align-right{
            text-align: right;
        }
        .align-left{
            text-align: left;
        }
        .blue{
            color: #00057a;
        }
        .pink{
            color: #ff0090;
        }
        .jc-c{
            justify-content: center;
        }
        .jc-sb{
            justify-content: space-between;
        }
        .jc-stretch{
            justify-content: stretch;
        }
        .text-upper{
            text-transform: uppercase;
        }
        .text-capitalize{
            text-transform: capitalize;
        }
        .btn{
            padding: 4px 10px;
            font-weight: 600;
            border: none;
            text-decoration: none;
            transition: .4s ease;
            cursor: pointer;
            outline: none;
            letter-spacing: .8px;
            transition: .5s ease;
        }
        .btn.disabled{
            opacity: .7 !important;
            cursor: not-allowed !important;
            &:hover{
                border: none !important;
                background: var(--voskoolBagColor) !important;
                /* color: #eee !important; */
            }
        }
        .border-1-black{
            border: 1px solid #000;
        }
        .hover-bg-app-opp-theme{
            transition: .4s ease;
            :hover{
                background: ${app_opp_theme};
                color: ${app_opp_text_color};
            }
        }
        .hover-border{
            transition: .4s ease;
            :hover{
                border: 1px solid ${app_opp_theme};
            }
        }
        .hover-opacity{
            transition: .3s ease;
            :hover{
                opacity: .85;
            }
        }
        .btn.bordered{
            border: 1px solid #000;
        }
        .btn.br{
            border-radius: 6px;
        }
        .br-circle{
            border-radius: 50%;
        }
        .btn.bordered-grey{
            border: 1px solid #666;
        }
        .btn.bordered-blue{
            border: 1px solid #00057a;
        }
        .btn.bordered-red{
            border: 1px solid red;
        }
        .disp-none{
            display: none;
        }

        .disp-nav-none-block{
            display: none;
            @media(max-width: 780px){
                display: block;
            }
        }
        .disp-nav-block-none{
            display: block;
            @media(max-width: 780px){
                display: none;
            }
        }
        .disp-all-nav-none{
            display: none;

            @media(max-width: 780px){
                display: none;
            }

        }
        
        .bg-red{
            background: #ff0090;
        }
        .bg-black{
            background: #000;
        }
        .bg-blue{
            background: #00057a;
        }
        .bg-green{
            background: #00ff90;
        }
        .bg-yellow{
            background: #aaac00;
        }
        .bg-none{
            background: none;
        }
        .mybtn{
            padding: 10px 15px;
            border: none;
            cursor: pointer;
            
            border-left: 8px solid #ff0090;  
            white-space: nowrap;
            box-shadow: 2px 0 10px rgba(0,0,0,0.6);
            font-weight: 500;
            border-radius: 10px;
            transition: 0.5s ease;
            -webkit-transition: 0.5s ease;

            &:hover{
            border-left: 0px solid #ff0090;
            border-right: 8px solid #ff0090;
            color: #ccc;
            }
        }
        .nav-bottom{
            bottom: 100%;
            @media(max-width: 780px){
                
            }
        }
        .nav-mrt-20-780{
            margin-left: 5px;
            margin:10px 0;
            @media(max-width: 780px){
                margin:8px 0;
                margin-left: 10px;
            }
        }
        .nav-dd-anim{
            animation: animDD .3s ease;

            @keyframes animDD{
                from{
                    opacity: 0;
                }
                to{
                    opacity: 1;
                }
            }
        }
        .nav-mrt-20{
            margin-left: 5px;
            margin-top: 5px;
            @media(max-width: 780px){
                margin-top: 20px;
                margin-left: 0px;
            }
        }
        
        .disp-block{
            display: block;
        }
        .fw-700{
            font-weight: 700 !important;
        }
        .fw-600{
            font-weight: 600 !important;
        }
        .fw-500{
            font-weight: 500 !important;
        }
        .link{
            font-weight: 700;
            display: inline-block;
            text-decoration: none;
        }
        .disp-flex{
            display: flex;
            width: 100%;
        }
        .disp-flex .row{
            flex-direction: row;
        }
        .disp-flex .column{
            flex-direction: column;
        }
        .disp-flex .wrap{
            flex-wrap: wrap;
        }
        .disp-flex .align-middle{
            justify-content: center;
            align-items: center;
            
        }
        .disp-flex .v-align-middle{
            height:100%;
            margin: auto;
            align-items: center;
        }
        .nav-wrapper-width{
            width: 175px;
            @media (max-width: 991px){
                width: 175px;
            }
            @media (max-width: 780px){
                width: 175px;
            }
        }
        .padd-4px{
            padding: 4px;
        }
        .mr-t-12px{
            margin-top: 12px;
        }
        .mr-t-4px{
            margin-top: 4px;
        }
        .mr-b-4px{
            margin-bottom: 4px;
        }
        .mr-l-4px{
            margin-left: 4px;
        }
        .mr-r-4px{
            margin-right: 4px;
        }
        .mr-4px{
            margin: 4px;
        }
        .nav-icon-padd{
            
            position: relative;
            padding: 0;
            @media (max-width: 780px){
                padding: 0;
               
            }
        }
        img.user-icon{
            width: 45px;
            height: 38px;
        }
        img.large-icon{
            max-width: 155px;
            max-height: 138px;
        }
        i.user-icon{
            font-size: 2rem;
            transition: .4s ease;
            margin-top: 2px;
            @media(max-width: 540px){
                font-size: 1.9rem;
                
            }
            :hover{
                transform: scale(0.9);
            }
        }
        img.country-icon{
            width: 15px;
            height: 15px;
        }
        img.sm-icon{
            width: 25px;
            height: 25px;
        }
        .translateX-200-sm{
            transform: translateX(200px) !important;
            @media(max-width:1028px){
                transform: translateX(150px) !important;
            }
            @media(max-width:780px){
                transform: translateX(100px) !important;
            }
            @media(max-width:540px){
                transform: translateX(20px) !important;
            }
        }
        .b-1-app-bg{
            border: 1px solid ${app_bg_color};
        }
        .br-15{
            border-radius: 15px;
        }
        .br-10{
            border-radius: 10px;
        }
        .br-5{
            border-radius: 5px;
        }
        .mr-top-2{
            margin-top: 2px;
        }
        .bg-o-anim-green{
            animation: animate_bg_green 1s ease ;
            --webkit-animation: animate_bg_green 1s ease;
            @keyframes animate_bg_green{
                0%{
                    background: rgba(0,0,0,0);
                    opacity: 1;
                }
                50%{
                    opacity: .3;
                }
                100%{
                    opacity: 1;
                    background: rgba(10, 255, 50, .5);
                }
            }
        }
    }
`;
export const GlobalStyle = createGlobalStyle`
    :root{
        --maxWidth: 1280px;
        --schoolColor: ${schColor};
        --schoolBagColor: ${schBagColor};
        --voskoolBagColor: ${voskoolBagColor};
        --lightGrey: #eee;
        --medGrey: #353535;
        --darkGrey: #1c1c1c;
        --fontSuperSmall: .8rem;
        --fontSmall: 1rem;
        --fontMed: 1.2rem;
        --fontBig: 1.3rem;
        --fontSuperBig: 2.3rem;
        --assignedColor: #fff;
        --unAssignedColor: #000;
    }
    html {
	scroll-behavior: smooth;
    }
    *{
        box-sizing: border-box;
    }
    body{
        margin: 0;
        padding: 0;
        font-family: "Raleway", sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background: ${voBgColor};
        .overflow-y-none{
            overflow-y: hidden;
        }
        .text-decoration-none{
            text-decoration: none;
        }
        .bg-light-grey{
            background: #ccc;
        }
        .z-9{
            z-index: 9;
        }
        .bg-black{
            background: #000;
            color: #eee;
        }
        .f-c.b-red{
            border: 1px solid red;
        }
        .w-100{
            width: 100%;
        }
        .w-50{
            width: 50%;
        }
        .close-modal-cont{
            position: absolute;
            top: 0;
            right: 0;
            padding: 10px;
            border-top-left-radius: 50%;
            border-top-right-radius: 50%;

            :hover i{
                opacity: .5;
            }
            i{
                transition: .3s ease; 
                
            }
        }
        .form-group{
            margin-bottom: 10px;
            position: relative;

            .form-icon-group{
                position: absolute;
                top: 30%;
                transition: .3s ease;
                font-size: 1rem;
                i.transparent{
                    opacity: .6;
                }
            }
            .form-icon-group.right{
                right: 5%;
            }
        }
        .form-group-btn{
            margin-top: 35px;
            margin-bottom: 5px;
        }
        
        .form-control{
            width: 100%;
            padding: 8px;
            border-radius: 5px;
            border: 1px solid #888;
            color: #013000;
            outline: none;
            transition: .5s ease;
            font-weight: 500;

            :focus{
                box-shadow: 0 0 3px 5px rgba(0,0,0,0.3);
            }
        }
        .f-c{
            width: 100%;
            padding: 4px;
            border-radius: 5px;
            border: 1px solid #aaa;
            color: #013000;
            outline: none;
            transition: .3s ease;
            font-weight: 500;

            :focus{
                box-shadow: 0 0 3px 5px rgba(0,0,0,0.1);
            }
        }
        
        .font-super-small{
            font-size: .7rem !important;
            @media(max-width: 780px){
                font-size: .65rem !important;
            }
            @media(max-width: 540px){
                font-size: .6rem !important;
            }
            @media(max-width: 320px){
                font-size: .5rem !important;
            }
            @media(max-width: 280px){
                font-size: .45rem !important;
            }
        }
        .font-very-small{
            font-size:  .8rem !important;
            @media(max-width: 780px){
                font-size: .75rem !important;
            }
            @media(max-width: 540px){
                font-size: .65rem !important;
            }
            @media(max-width: 320px){
                font-size: .55rem !important;
            }
            @media(max-width: 280px){
                font-size: .5rem !important;
            }
        }
        .font-slightly-small{
            font-size: .85rem !important;
            @media(max-width: 780px){
                font-size: .75rem !important;
            }
            @media(max-width: 540px){
                font-size: .7rem !important;
            }
            @media(max-width: 320px){
                font-size: .6rem !important;
            }
            @media(max-width: 280px){
                font-size: .5rem !important;
            }
        }
        .font-small{
            font-size: 1rem !important;
            @media(max-width: 780px){
                font-size: .8rem !important;
            }
            @media(max-width: 540px){
                font-size: .75rem !important;
            }
            @media(max-width: 320px){
                font-size: .6rem !important;
            }
            @media(max-width: 280px){
                font-size: .55rem !important;
            }
        }
        .font-big{
            font-size: 1.5rem !important;
            @media(max-width: 780px){
                font-size: 1.2rem !important;
            }
            @media(max-width: 540px){
                font-size: 1.1rem !important;
            }
            @media(max-width: 370px){
                font-size: 1rem !important;
            }
            @media(max-width: 320px){
                font-size: .8rem !important;
            }
            @media(max-width: 280px){
                font-size: .7rem !important;
            }
            
        }
        .btn{
            padding: 10px 35px;
            font-weight: 600;
            border: none;
            text-decoration: none;
            cursor: pointer;
            outline: none;
            letter-spacing: .8px;
            transition: .5s ease;
        }
        .btn.shadow{
            box-shadow: 0 0 5px 8px rgba(0,0,0,0.1);
        }
        .btn.hover-b{
            background: transparent;
            border: 1px solid #000;
            color: #000;
        }
        .btn.hover-a:hover{
            background: transparent;
            border: 1px solid #000;
            box-shadow: none;        
            color: #000;
        }
        .btn.disabled{
            opacity: .7 !important;
            cursor: not-allowed !important;
            &:hover{
                border: none !important;
                background: var(--voskoolBagColor) !important;
                /* color: #eee !important; */
            }
        }
        .btn-br{
            font-size: 1rem;
            padding: 7px;
            border: 1px solid var(--voskoolBagColor);
            font-weight: 700;
            border-radius: 5px;
            cursor: pointer;
            outline: none;
            transition: .5s ease;
            display: inline-block;
            @media(max-width: 1280px){
                font-size: .8rem; 
                padding: 5px;
            }
            @media(max-width: 430px){
                font-size: .7rem; 
                padding: 5px;
            }
            :hover{
                background: #333;
                color: #ccc;
            }
        }
        .btn-bg.disabled{
            opacity: .7;
            cursor: not-allowed;
            &:hover{
                border: none;
                background: var(--voskoolBagColor);
                color: #eee;
            }
        }
        .btn-bg{
            font-size: 1rem;
            padding: 8px;
            border: none;
            background: var(--voskoolBagColor);
            color: #ddd;
            outline: none;
            font-weight: 700;
            border-radius: 5px;
            cursor: pointer;
            letter-spacing: .6px;
            transition: .5s ease;
            display: inline-block;
            @media(max-width: 1280px){
                font-size: .8rem; 
                padding: 5px;
            }
            @media(max-width: 430px){
                font-size: .7rem; 
                padding: 5px;
            }
            :hover{
                border: 1px solid var(--voskoolBagColor);
                background: transparent;
                color: #333;
            }
        }
        .btn-da{
            font-size: 1rem;
            padding: 8px;
            border: none;
            background: #f00;
            color: #eee;
            outline: none;
            font-weight: 700;
            border-radius: 5px;
            cursor: pointer;
            letter-spacing: .6px;
            transition: .5s ease;
            display: inline-block;
            @media(max-width: 1280px){
                font-size: .8rem; 
                padding: 5px;
            }
            @media(max-width: 430px){
                font-size: .7rem; 
                padding: 5px;
            }
            :hover{
                color: #ccc;
            }
        }
        .small-800-disp-none{
            @media(max-width:800px){
                display: none;
            }
        }
        .form-br-15{
            border: none;
            border-radius: 15px;
            padding: 12px;
        }
        .br-15{
            border-radius: 15px;
        }
        .br-10{
            border-radius: 10px;
        }
        .br-5{
            border-radius: 5px;
        }
        .align-right{
            text-align: right;
        }
        .text-upper{
            text-transform: uppercase;
        }
        .text-capitalize{
            text-transform: capitalize;
        }
        .rem1{
            font-size: 1rem;
            @media(max-width: 1280px){
                font-size: .9rem;
            }
            @media(max-width: 540px){
                font-size: .8rem;
            }
            @media(max-width: 320px){
                font-size: .7rem;
            }
        }
        .rem1_{
            font-size: .9rem;
            @media(max-width: 1280px){
                font-size: .8rem;
            }
            @media(max-width: 540px){
                font-size: .6rem;
            }
            @media(max-width: 320px){
                font-size: .55rem;
            }
        }
        .read-card-btn{
            outline: none;
            background: none;
            border: none;
            padding: 10px;
            font-size: .8rem;
            font-weight: 600;
            color: #ddd;
            cursor: pointer;
            border: 1px solid #ddd;
            transition: .5s;
            border-radius: 5px;

            @media (max-width: 780px){
                font-size: .7rem;
            }
            @media (max-width: 540px){
                font-size: .65rem;
            }
            :hover{
                border-color: var(--voskoolBagColor);
            }
        }
        .small-320-disp-none{
            @media(max-width:320px){
                display: none;
            }
        }
        .flex-header{
            font-size: 1.7rem;
            color: #888;

            @media (max-width: 768px){
                font-size: 1.2rem;
            }
        }
        .flex-chunk-header{
            font-size: 1.7rem;
            color: #26223a;

            @media (max-width: 768px){
                font-size: 1.2rem;
            }
        }
        
        .med-1024-disp-hidden{
            @media(max-width:1024px){
                display: none;
            }
        }
        .med-1024-disp-visible{
            visibility: hidden;
            @media(max-width: 1024px){
                visibility: visible;
            }
        }
        .fw-700{
            font-weight: 700 !important;
        }
        .fw-600{
            font-weight: 600 !important;
        }
        .flex-100{
            flex: 0 0 100%;
            max-width: 100%;
            margin-top: 15px;
        }
        .flex-90{
            flex: 0 0 90%;
            max-width: 90%;
            margin-bottom: 10px;
        }
        .flex-10{
            flex: 0 0 10%;
            max-width: 10%;
            margin-bottom: 9px;
        }
        .flex-70{
            flex: 0 0 70%;
            max-width: 70%;
            margin-bottom: 9px;
            @media (max-width: 780px){
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
        .flex-30{
            flex: 0 0 30%;
            max-width: 30%;
            margin-bottom: 9px;
            @media (max-width: 780px){
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
        .flex-33{
            flex: 0 0 33.33%;
            max-width: 33.33%;
            margin-bottom: 9px;
        }
        .chunk-disp-none{
            display: none;

            @media(max-width: 540px){
                display: block;
            }
        }
        .chunk-resp-disp-none{

            @media(max-width: 540px){
                display: none;
            }
        }
        .flex-70-chunk-img-top-resp{
            flex: 0 0 80%;
            max-width: 80%;
            margin-bottom: 9px;
            padding: 2px;
            @media(max-width:1280px){
                flex: 0 0 65%;
                max-width: 65%;
            }
            @media(max-width:540px){
                flex: 0 100%;
                max-width: 100%;
            }
            @media(max-width:320px){
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
        .flex-70-chunk{
            flex: 0 0 80%;
            max-width: 80%;
            margin-bottom: 9px;
            padding: 4px;
            @media(max-width:1280px){
                flex: 0 0 65%;
                max-width: 65%;
            }
            @media(max-width:540px){
                flex: 0 65%;
                max-width: 65%;
            }
            @media(max-width:320px){
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
        .flex-30-chunk{
            flex: 0 0 20%;
            max-width: 20%;
            margin-bottom: 9px;
            @media (max-width: 1280px){
                flex: 0 0 35%;
                max-width: 35%;
            }
            @media(max-width:540px){
                flex: 0 0 35%;
                max-width: 35%;
            }
            @media(max-width:320px){
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
        .align-center{
            text-align: center;
        }
        .h-100vh{
            height: 100vh;
        }
        .h-110vh{
            height: 110vh;
        }
        .bg_white{
        background-color: #fff;
        }
        .bg_grey{
            background-color: #eee;
        }
        .bg_dark_grey{
            background-color: #333;
        }
        .bg_dark{
            background-color: #000;
        }
        .abs-right{
            right: 0;
        }
        .abs-left{
            left: 0;
        }
        .abs-top{
            top: 0;
        }
        .abs-bottom{
            top: 0;
        }
        .disp-none{
            display: none;
        }
        .green{
            color: #0f0;
        }
        .red{
            color: #f00;
        }
        .menu-cont{
            display: inline-block;
            position: relative;
            
            .left-menu{
                right: 100%;
                top: -130%;
                @media(max-width: 800px){
                    top: -110%;
                }
            }
            .right-menu{
                left: 120%;
                top: -130%;
                @media(max-width: 800px){
                    top: -150%;
                }
            }
            .left-tooltip{
                ::after{
                    top: 50%;
                    right: 100%;
                }
            }
            .right-tooltip{
                ::after{
                    top: 50%;
                    left: 96.5%;
                }
            }
            .menu{
                width: 200px; 
                position: absolute;
                visibility: hidden;
                background: var(--voskoolBagColor);
                
                padding: 5px 10px;
                z-index: 1;
                border-radius: 7px;
                @media(max-width: 800px){
                    width: 150px;
                }
                @media(max-width: 320px){
                    width: 100px;
                }
                
                ::after{
                    content: "";
                    position: absolute; 
                    margin-top: -5px;
                    border-width: 5px;
                    border-style: solid;
                    border-color: transparent var(--voskoolBagColor) transparent transparent;
                }
                p{
                    color: #ccc;
                    font-size: .7rem;
                    font-weight: 500;
                    text-transform: initial;

                    @media(max-width: 500px){
                        font-size: .5rem;
                    }
                    @media(max-width: 320px){
                        font-size: .4rem;
                    }
                }
            }
            :hover{
                .menu{
                    visibility: visible;
                }
            }
        }
        .anim{
            animation: anim_img 8s ease;
            --webkit-animation: anim_img 8s ease;
            @keyframes anim_img {
                from{
                    opacity: 0;
                }
                to{
                    opacity: 1;
                }
            }
        }
        .anim_img{
            animation: anim_img 10s ease;
            --webkit-animation: anim_img 10s ease;
            @keyframes anim_img {
                from{
                    opacity: 0;
                }
                to{
                    opacity: 1;
                }
            }
        }
        .anim_new_img{
            animation: anim_img 5s ease;
            --webkit-animation: anim_img 5s ease;
            @keyframes anim_img {
                from{
                    opacity: 0;
                }
                to{
                    opacity: 1;
                }
            }
        }
        .anim-rotate{
            :hover{
                animation: anim_rot 1s ease;
                --webkit-animation: anim_rot 1s ease;
                @keyframes anim_rot {
                    from{
                        transform: rotate(360deg);
                    }
                    to{
                        transform: rotate(0deg);
                    }
                }
            }
        }
        .ss{
            padding: 5px 10px;
            font-size: 1.5rem;
            background: #ddd;
            border-radius: 50%;
            color: var(--voskoolBagColor);
            transition: .3s;

            :hover{
                opacity: .8;
            }
        }
        .ss-text{
            background: #ddd;
            border-radius: 50%;
            color: var(--voskoolBagColor);
            font-size: .7rem;
            padding: 6px;
          }
        .select-top{
            position: absolute;
            z-index: 1;
            top: 0;
            left: 45%;
                
        }
        .arrow-select-left{
            position: absolute;
            z-index: 1;
            left: 0;
            top: 45%;

        }
        .arrow-select-right{
            position: absolute;
            z-index: 1;
            right: 0;
            top: 45%;

            
        }
        .white{
            color: #eee;
        }
        img.user-icon{
            width: 45px;
            height: 38px;
        }
        i.user-icon{
            font-size: 2rem;
            transition: .4s ease;
            margin-top: 2px;
            @media(max-width: 540px){
                font-size: 1.9rem;
                
            }
            :hover{
                transform: scale(0.9);
            }
        }
        img.country-icon{
            width: 15px;
            height: 15px;
        }
    }
`;