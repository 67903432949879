import React, { useState, useEffect, useMemo} from 'react';

import Grid from "components/app/school/SmallerComponents/grid";
import GridContent from "components/app/school/SmallerComponents/gridContent";
import Section from "components/app/school/SmallerComponents/Section";
// import Mallet from 'components/app/school/SmallerComponents/mallet';
// import View from 'components/app/school/SmallerComponents/mallet/view';
import Notification from "components/app/school/SmallerComponents/notification";

import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";

import { appServices } from 'api/schoolApp/services';


import Spinner from 'components/spinner';
import Loader from 'components/app/school/SmallerComponents/loader';
import Note from 'components/app/school/SmallerComponents/NOTE';


const StudentPaymentHistoryContent = ({data, uid, school_id, school_title, student_name=''}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);

    const [error, setError] = useState(initialErrorState);
    const [success, setSuccess] = useState(initialSuccessState);
    const [warning, setWarning] = useState(initialSuccessState);

    const [schoolVoSpace, setVoSchoolSpace] = useState({id:0});
    const [studentState, setStudentState] = useState({amt_paid:'', token:''});
    
    const [preloading, setPreloading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [isTokenGenerated, setIsTokenGenerated] = useState(false);
    const [reload, setReload] = useState(true);


    const fetchSchoolVoSpace = (id) => {
        if(id){
            setError({data:[], title:''});
            setPreloading(true);
            appServices.getSchoolVoSpace(id).then(res => {
                setVoSchoolSpace(res.data);
                setPreloading(false);
            }).catch(e => {
                setPreloading(false);
                setError({data:["Internal Server Error"], title:"VoSpace Data Fetch Error", click_text:'Reload', handleClick: () => setReload(true)});
            })
        }
    }
    

    const handleChange = e => {
        setStudentState({...studentState, [e.target.name]:e.target.value});
    }


    const onSubmitAccess = e => {
        e.preventDefault();
        setWarning({id:0, title:'', text:''});
        if(data.id){
            setTimeout(() => {
                setWarning({id:5, click_text:`Yes`, 
                    title:"Warning", text:"This student will be given tuition paid feature access, are you sure this student has paid their tuition fees?",
                    handleClick: () => {
                        setLoading(true);
                        setError(initialErrorState);
                        setSuccess(initialSuccessState);
                        setWarning({id:0, title:'', text:''});
                        let dt= {
                            token_entered: studentState.token,
                            amt_stud_paid: studentState.amt_paid,
                            student: data.id
                        }
                        appServices.createSchoolStudentVoSpacePayment(dt).then(res => {
                            window.location.reload();
                            setLoading(false);
                            setSuccess({text:"Student Paid Successfully", title:"Success"});
                        }).catch(e => {
                            setLoading(false);
                            setError({title:'Payment Error', data:[e.response.data.detail ? e.response.data.detail :'Something went wrong']});
                        })
                    }
                });
            });
            return;
        }
        setError({title:'Payment Error', data:['Data requirement not met, reload this page']});

    }
    const onGenerateToken = () => {
        setLoading(true);
        setError(initialErrorState);
        appServices.createSchoolVoSpaceToken().then(res => {
            setLoading(false);
            setIsTokenGenerated(true);
        }).catch(e => {
            setError({title:'Token Generation Error', data:[e.response.data.detail ? e.response.data.detail :'Something went wrong']});
            setLoading(false);
        })
    }
    

    useEffect(() => {
        if(reload && school_id){
            fetchSchoolVoSpace(school_id);
            setReload(false);
        }
    }, [reload, school_id]);

    


    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} click_text={error.click_text} handleClick={error.handleClick} />)
    })

    return(
        <>
        {loading && <Loader />}
        {!preloading ? (
            <>
            <Section>
                <NotificationWrapper>
                    {error.title && (errorHandler)}
                    {warning.id && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)}
                    {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
                </NotificationWrapper> 
                <div className='disp-flex wrap jc-sb'>
                    <div className='disp-block'>
                        <span className="font-med fw-700 disp-block app-text-color">Payment</span><br />
                    </div>
                    <div className='disp-block'>
                        <button type="button" onClick={() => onGenerateToken()} className={!isTokenGenerated ? 'btn app-bg-color hover-opacity font-very-small app-bg-text-color br' : 'btn br app-bg-text-color app-bg-color disabled'} disabled={isTokenGenerated}>Generate VoSpace Token</button>
                    </div>
                </div>
                {isTokenGenerated && (
                    <div className='disp-block align-center'>
                        <span className='font-super-small fw-600'><span className='green'>Token pin generated successfully</span> and has been sent to your mailbox, please check your email and proceed</span>
                    </div>
                )}
                
                {schoolVoSpace.id > 0 && (
                    <>
                    {!data.is_cs_paid ? (
                        <Grid layout='lauto'>
                            <GridContent header_text={`Make Student Payment Access`} header_icon="fas fa-coins">
                                <form onSubmit={e => onSubmitAccess(e)}>
                                    <div className='disp-block'>
                                        <label className='font-super-small app-text-color'>Student's Name</label>
                                        <input type="text" name="stud_name" disabled defaultValue={student_name} className='form-control disabled app-text-color font-slightly-small' placeholder='Enter Surname' />
                                    </div>
                                    <div className='disp-block' style={{paddingRight:'6px'}}>
                                        <label className='font-super-small app-text-color'>Amount Paid<span className='font-super-small'>(optional)</span></label>
                                        <input type="number" name="amt_paid" onChange={e => handleChange(e)} value={studentState.amt_paid} className='form-control-theme app-text-color font-slightly-small' placeholder='Enter Amount Paid' />
                                        <Note><span className='font-very-small'>This should be entered only if the student has already made direct payment to the school</span> </Note>
                                    </div>
                                    <div className='disp-block' style={{paddingRight:'6px'}}>
                                        <label className='font-super-small app-text-color'>VoSpace Token Pin<span className='red font-small'>*</span></label>
                                        <input type="text" name="token" required onChange={e => handleChange(e)} value={studentState.token} className='form-control-theme app-text-color font-slightly-small' placeholder='E.g TOK***' />
                                    </div>
                                    <div className='disp-block align-center' style={{marginTop:'10px'}}>
                                        <button className='mybtn font-slightly-small app-bg-color app-bg-text-color'>Grant Access <i className='fas fa-paper-plane'></i></button>
                                    </div>
                                </form>
                                <Note><span className='font-super-small'>Please make sure this student has paid their due tuitions before granting access</span></Note>
                                <Note><span className='font-very-small'>Payment entered here will not reflect on the finance dashboard</span></Note>
                            </GridContent>
                        </Grid>
                    ) : (
                        <div className='disp-block align-center'>
                            <span className='font-med green fw-600'>Student current term payment has been made successfully</span>
                        </div>
                    )}
                        
                    </>
                )}
            </Section>
            </>
        ) : (
            <>
            <div className='disp-block align-center'>
                <Spinner />
            </div>
            
            </>
        )}
        </>
    )
}

export default StudentPaymentHistoryContent;