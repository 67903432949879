import React, { useState, useEffect, useMemo, useCallback, useRef, Fragment } from 'react';
import { usePaystackPayment } from "react-paystack";
import { useNavigate, Link, useSearchParams } from 'react-router-dom';

import BreadCrumb from "components/breadCrumb";
import Note from 'components/app/school/SmallerComponents/NOTE';
import Notification from "components/app/school/SmallerComponents/notification";
import Grid from "components/app/school/SmallerComponents/grid";
import GridContent from "components/app/school/SmallerComponents/gridContent";
import Section from "components/app/school/SmallerComponents/Section";
import Mallet from 'components/app/school/SmallerComponents/mallet';
import Table from "components/app/school/SmallerComponents/tableUl";
import Tr from "components/app/school/SmallerComponents/tableLi";
import Td, {TdWrapper} from "components/app/school/SmallerComponents/tableInnerLi";
import {default as Grid2} from "components/app/register_frontend/SmallerComponents/grid";
import CartCard from 'components/app/register_frontend/SmallerComponents/card';

import Pagination from '@mui/material/Pagination';

import BlankLoader from "components/blankLoader";
import Loader from "components/app/school/SmallerComponents/loader";
import Spinner from "components/app/school/SmallerComponents/spinner";
import PreloaderLine from "components/preloaderLine";

import { ComponentWrapper } from "components/app/school/wrapper";
import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";

import { HeadComponent } from "components/head";
import { appServices } from 'api/schoolApp/services';
import { BACKEND_URL, PAYSTACK_LIVE_KEY } from "actions/url";

import { addItemToArray } from 'components/app/school/actions/array-utils/Add';
import { getPageSize } from 'components/app/school/actions/pageSize';
import { numberDigit } from 'components/app/school/actions/numberDigit';
import { convertNumber } from '../../actions/money';
import { runOut } from '../../actions/utils/runOutVospace';


const ManageStudent = ({school_id, school_title, school={}, user_type, user={email:''}}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);
    const initialPaginationState = useMemo(() => ({count:0, previous:undefined, next:undefined, results:[]}), []);

    const [error, setError] = useState(initialErrorState);
    const [success, setSuccess] = useState(initialSuccessState);
    const [warning] = useState(initialSuccessState);

    const [tableState, setTableState] = useState({class:'', sbid:'', q:'', p:1, size:100, pre:true});
    const [currSessionState, setCurrSessionState] = useState({id:0});
    const [voSpaceState, setVoSpaceState] = useState({id:0});
    const [schoolVoSpace, setVoSchoolSpace] = useState({id:0});
    const [config, setConfig] = useState({
        reference: '',
        email:user.email,
        amount: 0,
        publicKey:PAYSTACK_LIVE_KEY
    })

    const [allClassesData, setAllClassesData] = useState([]);
    const [allSchoolBranchData, setAllSchoolBranchData] = useState([]);
    const [allVospacePlan, setAllVospacePlan] = useState([]);
    const [allStudentData, setAllStudentData] = useState(initialPaginationState);

    const [navigate, setNavigate] = useState('');

    const [reloadData, setReloadData] = useState(true);
    const [reloadVoSpace, setReloadVoSpace] = useState(false);
    const [tableStateChanged, setTableStateChanged] = useState(false);
    const [tableStateSearch, setTableStateSearch] = useState(false);
    const [isVoSpace, setIsVoSpace] = useState(false);
    const [isSearchVoSpace, setIsSearchVoSpace] = useState(true);
    const [isVerifyPayment, setIsVerifyPayment] = useState(true);
    const [isStartPayment, setIsStartPayment] = useState(false);


    const [loading, setLoading] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);
    const [preloading, setPreloading] = useState(true);
    const [preloading2, setPreloading2] = useState(true);

    const nav = useNavigate();

    const [searchParam] = useSearchParams();

    const selectedVoSpaceRef = useRef(null);

    const initializePayment = usePaystackPayment(config);

    const fetchAllSchoolBranch = (sid, pre=true) => {
        if(sid){
            setTableStateChanged(false);
            appServices.getAllSchoolBranch(sid).then(res => {
                setAllSchoolBranchData([...res.data]);
                setTableState((prev) => !prev.sbid ? {...prev, sbid:res.data[0].id, pre:pre} : {...prev});
                setTableStateChanged(true);
            }).catch(e => {
                setAllSchoolBranchData([]);
                setError({data:["Internal Server Error"], title:"School Branch Data Fetch Error", click_text:'Reload', handleClick: () => setReloadData(true)});
            })
        }
    }
    const fetchAllClasses = (school_id) => {
        if(school_id){
            setError({data:[], title:''});
            appServices.getAllClassSchoolItem(school_id).then(res => {
                setAllClassesData(addItemToArray(res.data));
            }).catch(e => {
                setAllClassesData([]);
                setError({data:["Internal Server Error"], title:"Class Data Fetch Error", click_text:'Reload', handleClick: () => setReloadData(true)});
            })
        }
    }
    const fetchSchoolVoSpace = (id) => {
        if(id){
            setError({data:[], title:''});
            appServices.getSchoolVoSpace(id).then(res => {
                setVoSchoolSpace(res.data);
            }).catch(e => {
                setError({data:["Internal Server Error"], title:"VoSpace Data Fetch Error", click_text:'Reload', handleClick: () => setReloadData(true)});
            })
        }
    }
    const fetchSession = (school_id) => {
        if(school_id){
            appServices.getCurrentSession(school_id).then(res => {
                setCurrSessionState(res.data);
            }).catch(e => {
                setCurrSessionState({});
            })
        }
    }
    
    const fetchAllStudent = (loader=false, sid, sbid, cls_id="", q="", p=1, size=100) => {
        if(sid && sbid){
            setPreloading(loader);
            setTableLoading(!loader);
            setError({data:[], title:''});
            setPreloading2(true);
            appServices.getAllStudent_Admin(sid, sbid, q, cls_id, p, size).then(res => {
                setAllStudentData((prev) => {return {...res.data, results:res.data.results}});
                setTableLoading(false);
                setPreloading(false);
                setPreloading2(false);
            }).catch(e => {
                setError({data:["Internal Server Error"], title:"Students Data Fetch Error", click_text:'Reload', handleClick: () => setReloadData(true)});
                setTableLoading(false);
                setPreloading(false);
                setPreloading2(false);
                setAllStudentData({count:0, previous:'', next:'', results:[]});
            })
        }
    }
    const fetchAllVospacePlan = (email="") => {
        appServices.getAllVoSpacePlan().then(res => {
            setAllVospacePlan(addItemToArray(res.data, {is_selected:false}));
            appServices.getSchoolVoSpacePayment().then(inner_res => {
                const result = inner_res.data;
                const filter = res.data.filter((it) => it.id === result.plan);
                setVoSpaceState((prev) => {return {...prev, ...result, ...filter[0], oid:result.id }}); 
                const toAmt = parseFloat(filter[0].amount)*100;
                setConfig((prev) => typeof prev === "object" ? {...prev, email:email, reference:result.ref_id, amount:toAmt} : prev);
                setTimeout(() => {
                    selectedVoSpaceRef.current?.scrollIntoView({ behavior: "smooth" });
                })
            })
        }).catch(e => {
            setError({data:["Internal Server Error"], title:"Data Fetch Error", click_text:'Reload', handleClick: () => setReloadData(true)});
        })
    }

    const onChangeTable = (e) => {
        setTableStateChanged(false);
        setTableStateSearch(false);
        if(e.target.name === "q"){
            setTableState({...tableState, [e.target.name]:e.target.value, pre:false});
            setTableStateChanged(true);
            setTableStateSearch(true);
            return;
        }
        setTableState({...tableState, [e.target.name]:e.target.value, pre:false});
        setTableStateChanged(true);
        
    }
    const handleTablePagination = (e, value) => {
        setTableStateChanged(false);
        const name = "p";
        setTableState({...tableState, [name]:value, pre:false});
        setTableStateChanged(true)
    }

    const onClickFreeVoSpace = () => {
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setLoading(true);
        const data = {school:school_id};
        appServices.createSchoolVoSpace(data).then(res => {
            setSuccess({title:'VoSpace Added Successfully', text:`+1 VoSpace Has Been Added and the Vospace is ready to use`});
            setTimeout(() => {
                window.location.href = `/${school_title}/student?is_vospace=true`;
            }, 3000);
        }).catch(e => {
            setLoading(false);
            setError({title:'Error', data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong']});
        })
    }

    const checkSchoolPayment = (id) => {
        const data = {paid_by:id}
        setLoading(true);
        appServices.makeSchoolVoSpacePayment(data).then(res => {
            setSuccess({title:'Success', text:`Payment Successful`});
            setTimeout(() => {
                setSuccess({title:'Success', text:`+${res.data.no_space} VoSpace Has Been Added Successfully... reloading`});
                setTimeout(() => {
                    window.location.reload();
                }, 4000)
            }, 3000)
        }).catch(e => {
            setLoading(false);
        })
    }

    const onVoSpacePayment = useCallback((ref) => {
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setLoading(true);
        let data = {paid_by:user.id}
        if(schoolVoSpace.id){
            appServices.makeSchoolVoSpacePayment(data).then(res => {
                setSuccess({title:'Success', text:`Payment Successful`});
                setTimeout(() => {
                    setSuccess({title:'Success', text:`+${res.data.no_space} VoSpace Has Been Added Successfully... reloading`});
                    setTimeout(() => {
                        window.location.reload();
                    }, 4000)
                }, 3000)
            }).catch(e => {
                setLoading(false);
                setError({title:'Error', data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong']});
            })
            return;
        }
        const dt = {school:school_id};
        appServices.createSchoolVoSpace(dt).then(() => {
            appServices.makeSchoolVoSpacePayment(data).then(res => {
                setSuccess({title:'Success', text:`Payment Successful`});
                setLoading(false);
                setTimeout(() => {
                    setSuccess({title:'Success', text:`+${res.data.no_space} VoSpace Has Been Added Successfully... reloading`});
                    setTimeout(() => {
                        window.location.reload();
                    }, 4000)
                }, 3000)
            }).catch(e => {
                setLoading(false);
                setError({title:'Error', data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong']});
            })    
        }).catch(e => {
            setLoading(false);
            setError({title:'Error', data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong']});
        })
    }, [initialErrorState, initialSuccessState, schoolVoSpace, school_id, user])
    const onClose = () => {

    }
    const onClickSecurePayment = () => {
        setIsStartPayment(true);
    }

    // useEffect(() => {
    //     fetchAllSchoolBranch(school_id);
    //     fetchAllClasses(school_id);
    //     fetchAllVospacePlan();
    // }, [school_id]);

    useEffect(() => {
        if(tableState.pre && tableStateChanged){
            fetchAllStudent(true, school_id, tableState.sbid);
            setTableStateChanged(false);
            return;
        }
        if(tableStateChanged && tableStateSearch && !tableState.pre){
            const timeout = setTimeout(() => {
                fetchAllStudent(false, school_id, tableState.sbid, tableState.class, tableState.q, tableState.p, tableState.size);
                setTableStateChanged(false);
                setTableStateSearch(false);
            }, 1000)
            return () => {
                clearTimeout(timeout);
            };
        }
        if(tableStateChanged && !tableState.pre){
            fetchAllStudent(false, school_id, tableState.sbid, tableState.class, tableState.q, tableState.p, tableState.size);
            setTableStateChanged(false);
            return;
        }
    }, [tableState, tableStateSearch, tableStateChanged, school_id]);

    useEffect(() => {
        if(reloadData && school.id){
            setReloadData(false);
            fetchAllSchoolBranch(school_id);
            fetchAllClasses(school_id);
            fetchAllVospacePlan(user.email);
            fetchSchoolVoSpace(school_id);
            fetchSession(school_id);
        }
        if(reloadVoSpace){
            fetchAllVospacePlan(user.email);
            setReloadVoSpace(false);
        }
    }, [reloadData, reloadVoSpace, school_id, school, user.email])

    useEffect(() => {
        if(user.id && isVerifyPayment){
            setIsVerifyPayment(false);
            checkSchoolPayment(user.id);
        }
    }, [user, isVerifyPayment])

    useEffect(() => {
        if(isStartPayment){
            setIsStartPayment(false);
            initializePayment(onVoSpacePayment, onClose);
        }
    }, [isStartPayment, onVoSpacePayment, initializePayment])

    useEffect(() => {
        if(navigate){
            nav(navigate);
        }
    }, [navigate, nav])

    useEffect(() => {
        if(searchParam.get('is_vospace') && isSearchVoSpace){
            setIsSearchVoSpace(false);
            setIsVoSpace(true);
        }
    }, [searchParam, isSearchVoSpace])

    const onClickVoSpace = () => {
        setIsVoSpace(!isVoSpace);
        if(!isVoSpace === false){
            setNavigate(`/${school_title}/student`)
        }
        if(!isVoSpace){
            setReloadVoSpace(true);
        }
    }
    const onClickSelectVoSpace = (item={}) => {
        setLoading(true);
        const data = {plan:item.id}
        appServices.createSchoolVoSpacePayment(data).then(res => {
            setVoSpaceState((prev) => {return {...prev, ...res.data, ...item, oid:res.data.id }}); 
            const toAmt = parseFloat(item.amount)*100;
            setConfig((prev) => typeof prev === "object" ? {...prev, email:user.email, reference:res.data.ref_id, amount:toAmt} : prev);
            setLoading(false);
            setTimeout(() => {
                selectedVoSpaceRef.current?.scrollIntoView({ behavior: "smooth" });
            })
        }).catch(e => {
            setLoading(false);
            setError({title:'Cart Error', data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong']});
        })
        
    }

    const onClickBeginPromotion = () => {
        setLoading(true);
        appServices.managePromotion(school_id).then(res => {
            setLoading(false);
            setSuccess({title:'Success', text:`All student have been promoted successfully`});
            fetchAllStudent(true, school_id, tableState.sbid);
            setTableState({...tableState, class:'', q:'', p:1});
            fetchSession(school_id);
        }).catch(e => {
            setLoading(false);
            setError({title:'Promote Error', data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong']});
        })
    }

    const onClickBeginReversePromotion = () => {
        setLoading(true);
        setError(initialErrorState);
        appServices.onceReverseManagePromotion(school_id).then(res => {
            setLoading(false);
            setSuccess({title:'Success', text:`All demoted student have been promoted successfully`});
            fetchAllStudent(true, school_id, tableState.sbid);
            setTableState({...tableState, class:'', q:'', p:1});
            fetchSession(school_id);
        }).catch(e => {
            setLoading(false);
            setError({title:'Promote Error', data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong']});
        })
    }

    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} click_text={error.click_text} handleClick={error.handleClick} />)
    })
    const insertSchoolBranchData = allSchoolBranchData.map((item) => {
        return(
            <option key={item.id} value={item.id}>{item.name}</option>
        )
    })
    const insertClassesData = allClassesData.map((item) => {
        return(
            <option key={item.id} value={item.id}>{item.name}</option>
        )
    })
    
    const statBtn = (stat={is_parent_access_app:false, is_setup_app_account:false, is_cs_paid:false, is_assigned_subjects:false}, type="", item={}) => {
        if(type === "sm"){
            return(
                <>
                    <span className='disp-block fw-500 text-capitalize app-text-color font-super-small'>Current Term Payment  <span className={`fw-700 ${stat.is_cs_paid ? 'fas fa-check green' : 'fas fa-times red'}`}></span> </span>
                    {item.is_show_promotion && (
                        <>
                        {item.is_promotion ? (
                            <>
                            {item.is_alumni ? (
                                <span className='font-super-small fw-600' style={{color:'darkgreen'}}>Promoted to Graduate</span>
                            ) : (
                                <span className='font-super-small fw-600' style={{color:'darkgreen'}}>Promoted From <i className='font-very-small'>{item.prev_class_name}</i> To <i className='font-slightly-small'>{item.class_name}</i></span>
                            )}
                            </>
                        ) : (
                            <>
                                <span className='font-super-small fw-600' style={{color:'red'}}>Demoted</span>
                            </>
                        )}
                        </>
                    )}
                </>
            )
        }
        return(
            <>
                <span className='disp-block fw-500 text-capitalize app-text-color font-super-small'>Current Term Payment  <span className={`fw-700 ${stat.is_cs_paid ? 'fas fa-check green' : 'fas fa-times red'}`}></span> </span><br />
                <span className='disp-block fw-500 text-capitalize app-text-color font-super-small'> parent access to kid's school app <span className={`fw-700 ${stat.is_parent_access_app ? 'fas fa-check green' : 'fas fa-times red'}`}></span> </span> <br />
                <span className='disp-block fw-500 text-capitalize app-text-color font-super-small'> kid's school app setup <span className={`fw-700 ${stat.is_setup_app_account ? 'fas fa-check green' : 'fas fa-times red'}`}></span> </span> <br />
                {item.is_show_promotion && (
                    <>
                    {item.is_promotion ? (
                        <>
                        {item.is_alumni ? (
                            <span className='font-super-small fw-600' style={{color:'darkgreen'}}>Promoted to Graduate</span>
                        ) : (
                            <span className='font-super-small fw-600' style={{color:'darkgreen'}}>Promoted From <i className='font-very-small'>{item.prev_class_name}</i> To <i className='font-slightly-small'>{item.class_name}</i></span>
                        )}
                        </>
                    ) : (
                        <>
                            <span className='font-super-small fw-600' style={{color:'red'}}>Demoted</span>
                        </>
                    )}
                    </>
                )}
            </>
        )
    }
    const noRecordFoundText = () => {
        return(
            <span className="red">No Record Found... <b className="app-bg-color-text" style={{cursor:'pointer', opacity:.8}} onClick={() => setReloadData(true)}>Reload</b></span>
        )
    }
    const insertTableData = () => {
        var i = 0;
        if(tableState.p > 1){
            const page = parseInt(tableState.p) - 1
            const size = parseInt(tableState.size) * page;
            i = size;
        }
        
        return(allStudentData.results.map((item) => {  
            i++;
            const time = new Date().getTime();
            // const data = {
            //     i: i,
            //     id: item.id,
            //     class_section: item.class_section,
            //     name: item.name,
            //     code:  item.code,
            //     group: item.group,
            //     genre: item.genre,
            //     type:"subject_form",
            // }
            const name = item.user_last_name ? `${item.user_last_name} ${item.user_first_name}` : `${item.last_name} ${item.first_name}`
            return(
                <Fragment key={i}>
                    <Tr sn={true} dlt_btn={item.is_cs_paid ? false : true} view_btn={true} handleViewClick={() => setNavigate(`/${school_title}/student/m/${item.uid}`)} edit_btn={false} >
                
                        <div className="c-data sn">
                            <span className="font-very-small">{numberDigit(item.adm_no, 4) }</span>
                        </div>
                        <div className="c-data"><div className="profile-cont"><img className='profile' src={`${BACKEND_URL}${item.user_profile_pic ? item.user_profile_pic : item.profile_pic}`} alt="Profile" /><span className={item.is_online > time ? 'circle green' : 'circle red'}></span></div><span className="font-very-small text-capitalize">{name.length > 20 ? `${name.slice(0, 20)}...`:name}</span></div> 
                        <div className="c-data"><span className="font-very-small text-capitalize">{item.phone_number ? `+${item.phone_number}` : `+${item.guardian_phone_number}`}</span></div>   
                        <div className="c-data"><span className="font-very-small text-capitalize">{item.class_name}</span></div>
                        <div className="c-data"><span className="font-very-small text-capitalize">{item.house_school_name ? item.house_school_name : item.house_name}</span></div>  
                        <div className="c-data">
                            <span className="font-very-small text-capitalize">
                                {statBtn({is_parent_access_app:item.is_parent_access_app, is_assigned_subjects: item.is_assigned_subjects, is_setup_app_account:item.is_setup_app_account, is_cs_paid:item.is_cs_paid}, "sm", item)}
                                
                            </span>
                        </div>    

                        <TdWrapper center header_text={numberDigit(item.adm_no, 4)} header_num={i}>
                            <Td profile_pic={`${BACKEND_URL}${item.user_profile_pic ? item.user_profile_pic : item.profile_pic}`} profile_status={time < item.is_online ? 'online':''} />
                            <Td heading="Name"  text_class='text-capitalize' text={name} />
                            <Td heading="Gender" text={item.user_gender ? item.user_gender : item.gender} />
                            <Td heading="Phone Number" text={item.phone_number ? `+${item.phone_number}` : `+${item.guardian_phone_number}`} />
                            <Td heading="Class Name" text_class='text-capitalize' text={item.class_name} />
                            <Td heading="House"  text_class='text-capitalize' text={item.house_school_name ? item.house_school_name : item.house_name} />
                            <Td heading="DOB" text={item.dob} />
                            <Td heading="Sponsored By" text={item.is_self_sponsored ? 'Self' : 'Parent / Guardian'} />
                            <Td heading="Status" text={statBtn({is_parent_access_app:item.is_parent_access_app, is_assigned_subjects: item.is_assigned_subjects, is_setup_app_account:item.is_setup_app_account, is_cs_paid:item.is_cs_paid}, "", item)} />
                            <Td heading="Date Joined" text={item.created} />
                            <Td link={`/${school_title}/student/m/${item.uid}`} text_link='View More'  />
                        </TdWrapper>
                    </Tr>
                </Fragment>
            )
        }))
    }
    
    return(
        <>
            <HeadComponent title='Manage all students' />
            <BreadCrumb />
            {preloading2 && <PreloaderLine />}
            <ComponentWrapper>
                <NotificationWrapper>
                    {error.title && (errorHandler)}
                    {warning.id && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)}
                    {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
                </NotificationWrapper>
                {schoolVoSpace.id > 0 && (
                    <>
                    <Grid layout='lauto'>
                        <GridContent header_text={`VoSpace Record`} header_icon="fas fa-hdd">
                            <Section>
                                <div className='disp-flex wrap'>
                                    <div className='flex-30 disp-flex jc-stretch'>
                                        <div className='disp-block padd-4px'>
                                            <span className='font-slightly-small app-text-color fw-500' style={{letterSpacing:'.4px'}}><i className='fas fa-server font-small'></i> VoSpace Remaining:</span>
                                        </div>
                                        <div className='disp-block align-center padd-4px' style={{paddingLeft:'7px'}}>
                                            <span className='font-med app-bg-color-text fw-600' style={{letterSpacing:'.6px'}}>{schoolVoSpace.vo_space_rem}</span>
                                        </div>
                                    </div>
                                    <div className='flex-30 disp-flex jc-stretch'>
                                        <div className='disp-block padd-4px'>
                                            <span className='font-slightly-small app-text-color fw-500' style={{letterSpacing:'.4px'}}><i className='fas fa-plus font-small'></i> Total Added:</span>
                                        </div>
                                        <div className='disp-block align-center padd-4px' style={{paddingLeft:'7px'}}>
                                            <span className='font-small app-text-color fw-600' style={{letterSpacing:'.6px'}}>{schoolVoSpace.vo_space_added}</span>
                                        </div>
                                    </div>
                                    <div className='flex-30 disp-flex jc-stretch'>
                                        <div className='disp-block padd-4px'>
                                            <span className='font-slightly-small app-text-color fw-500' style={{letterSpacing:'.4px'}}><i className='fas fa-road font-small'></i> Total Used:</span>
                                        </div>
                                        <div className='disp-block align-center padd-4px' style={{paddingLeft:'7px'}}>
                                            <span className='font-small app-text-color fw-600' style={{letterSpacing:'.6px'}}>{schoolVoSpace.vo_space_used}</span>
                                        </div>
                                    </div>
                                </div>
                                {runOut(schoolVoSpace.vo_space_rem)}
                            </Section>
                        </GridContent>
                    </Grid>
                    </>
                )}
                {!preloading && (
                    <>
                    {!isVoSpace ? (
                        <>
                        {!school.is_vos ? (
                            <>
                            <Note>You are currently not on any VoSpace plan, so all in-app charges will be subjected to the parent/student</Note>
                            <div className='disp-block align-right' style={{marginBottom:'20px'}}>
                                <button type="button" className='mybtn bg-green fw-500 app-text-color font-very-small' style={{padding:'5px', color:'#222'}} onClick={() => onClickVoSpace()}>Switch to VoSpace Plan</button>
                            </div>
                            </>
                        ) : (
                            <>
                            <div className='disp-block align-right' style={{marginBottom:'20px', marginTop:'20px'}}>
                                <button type="button" className='mybtn bg-green fw-500 app-text-color font-very-small' style={{padding:'5px', color:'#222'}} onClick={() => onClickVoSpace()}>Get More VoSpace</button>
                            </div>
                            </>
                        )}
                        
                        </>
                    ) : (
                        <>
                        <div className='disp-block padd-4px'>
                            <button className=' app-text-color font-very-small btn-br hover-opacity br padd-4px fw-600' onClick={() => onClickVoSpace()}><i className='fas fa-arrow-left'></i> Manage all students</button>
                        </div>
                        </>
                    )}
                    
                    </>
                )}
                {isVoSpace ? (
                    <>
                    <section style={{position:'relative'}}>
                    <Section>
                        {loading && (<Loader />)}
                        <div className='disp-block' style={{marginLeft:'5px', marginTop:'10px'}}>
                            <div className='disp-block'>
                                <span className='app-text-color font-big fw-700'>Benefits of using a VoSpace Plan</span>
                            </div>
                            <div className='disp-block'>
                                <ul>
                                    <li className='padd-4px'><span className='font-small app-text-color fw-500'>Saves money for both the school and student/parent</span></li>
                                    <li className='padd-4px'><span className='font-small app-text-color fw-500'>Total control of the app fee from the school fee management system, for online payments, click <Link to={`/${school_title}/school_fee`} target={'_blank'}>here</Link> to set your fee</span></li>
                                    <li className='padd-4px'><span className='font-small app-text-color fw-500'>Gives your student/parent a good reason for purchase of the app fee, while giving you total control of the fee</span></li>
                                    <li className='padd-4px'><span className='font-small app-text-color fw-500'>Remaining student space from previous term will be automatically redirected to the current term, so feel free to select any plan you like </span></li>
                                </ul>
                            </div>
                        </div>
                        <div className='disp-block' style={{marginLeft:'5px', marginTop:'40px'}}>
                            <div className='disp-block'>
                                <h2 className='app-text-color fw-700'>Choose a VoSpace</h2>
                            </div>
                        </div>
                        <Grid2 grid_width={"small-w"} style={{marginBottom:'20px'}}>
                            {!school.is_vos && (
                                <CartCard styleContainer={{background: 'var(--appTheme)'}} onClick={() => onClickFreeVoSpace()} name={'FREE VOSPACE'} name_under={`+10`}  price={0} is_free  />
                            )}
                            {!school.is_trial ? (
                                <>
                                {allVospacePlan.map((item, i, arr_mon) => {
                                    const first_pp = Math.ceil(parseInt(arr_mon[0].amount) / parseInt(arr_mon[0].no_space));
                                    const colorization = (arr=[]) => {
                                        if(arr.length - 1 === i){
                                            return 'rgba(255,215, 0, .7)';
                                        }
                                        if(arr.length - 2 === i){
                                            return 'rgba(198, 197, 198, .7)';
                                        }
                                        if(arr.length - 3 === i){
                                            return 'rgba(34, 243, 34, .4)';
                                        }
                                        return 'var(--appTheme)';
                                    }
                                    return(
                                        <Fragment key={i}>
                                            <CartCard onClick={() => onClickSelectVoSpace(item)} styleContainer={{background:colorization(arr_mon)}} name={item.name} name_under={`+${item.no_space}`} save_base_price={first_pp}  base_price={item.amount} price={item.amount}   curr={item.currency_symbol} is_active={voSpaceState.id === item.id ? true : false} />
                                        </Fragment>
                                    )
                                })}
                                </>
                            ) : (
                                <div className='disp-block align-center'>
                                    <span className='font-slightly-small app-text-color'>You are currently using a <b style={{color:'red'}}>TRIAL</b> Demo, upgrade to a preferred plan to get more vospace</span>
                                </div>
                            )}
                            
                        </Grid2>
                        {voSpaceState.id > 0 && (
                            <section className='app-theme' style={{marginLeft:'5px', marginTop:'70px', padding:'10px', borderRadius:'10px'}} ref={selectedVoSpaceRef}>
                                <div className='disp-block'>
                                    <div className='disp-block' style={{marginBottom:'15px'}}>
                                        <span className='app-text-color font-big fw-700'>Selected: <span className='font-med fw-600'>{voSpaceState.name}</span></span>
                                    </div>
                                    <div className='disp-block' style={{padding: '10px'}}>
                                        <div className='disp-flex wrap jc-sb' style={{marginBottom:'20px'}}>
                                            <div className='disp-block'>
                                                <span className='font-med app-text-color fw-600'>Allocated Space</span>
                                            </div>
                                            <div className='disp-block'>
                                                <span className='font-med app-text-color fw-500'>+{voSpaceState.no_space}</span>
                                            </div>
                                        </div>
                                        <div className='disp-flex wrap jc-sb' style={{marginBottom:'20px'}}>
                                            <div className='disp-block'>
                                                <span className='font-med app-text-color fw-600'>Amount</span>
                                            </div>
                                            <div className='disp-block'>
                                                <span className='font-big app-text-color fw-700'>{voSpaceState.currency_symbol}{convertNumber(voSpaceState.amount)}</span>
                                            </div>
                                        </div>
                                        <div className="disp-block align-center">
                                            <button type="button" className="mybtn font-small fw-700"  style={{background:'#3225ff', color:'#ccc'}} onClick={() => onClickSecurePayment()}>Begin Secure Payment</button>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        )}
                        
                    </Section>
                    </section>
                    </>
                ) : (
                    <>
                        <Grid layout='lauto'>
                            <GridContent header_text={`Manage All Student`} header_icon="fas fa-tasks">
                            {preloading ? (<ul>
                                <BlankLoader num={10} hide_circle={true} cont_style={{marginTop: '10px', marginLeft: '0'}} text_style={{padding: '10px'}} />
                            </ul>) : 
                            (
                            <>
                                
                                <Section>
                                    {schoolVoSpace.id > 0 && (
                                        <>
                                        {schoolVoSpace.vo_space_rem < 1 && (
                                            <div className='disp-block align-center' style={{marginTop: '10px', marginBottom: '10px'}}>
                                                <span className='font-small disp-block align-center red'><b>ERROR: New Students cannot be added</b></span>
                                            </div>
                                        )}
                                        </>
                                    )}
                                    {loading && (<Loader />)}
                                    <section>
                                        <div className='disp-block align-right'>
                                            <Link to="add" className='font-slightly-small' style={{textDecoration:'none', fontWeight:'600'}}><i className='fas fa-plus'></i> Add New Student</Link>
                                        </div>
                                    </section>
                                    <Mallet>
                                        <div className="flex-50-line"><label className='font-super-small'>Select School Location: 
                                            <select name="sbid" value={tableState.sbid} onChange={e => onChangeTable(e)} className='form-control-theme font-slightly-small' >
                                                {insertSchoolBranchData}
                                            </select></label>
                                        </div>
                                        <div className="flex-50-line"><label className='font-super-small'>Select Class: 
                                            <select name="class" value={tableState.class} onChange={e => onChangeTable(e)} className='form-control-theme font-slightly-small' >
                                                <option value={''}>All</option>
                                                {insertClassesData}
                                            </select></label>
                                        </div>
                                        <div className="flex-70-line"><label className='font-super-small'>Filter By:
                                            <input type='text' name="q" value={tableState.q} onChange={e => onChangeTable(e)} className='font-slightly-small form-control-theme' placeholder='Search Here' />
                                            </label>
                                        </div>
                                        <div className="flex-30-line align-right"><label className='font-super-small'>No. Of Student(s): <br />
                                            <select  name="size" value={tableState.size} onChange={e => onChangeTable(e)} className='font-slightly-small form-control-theme' >
                                                <option value={100}>100</option>
                                                <option value={300}>200</option>
                                                <option value={300}>300</option>
                                                <option value={400}>400</option>
                                                <option value={500}>500</option>
                                            </select>
                                            </label>
                                        </div>
                                    </Mallet>
                                    {tableLoading && <Spinner />}
                                    {currSessionState.is_init_promotion && (
                                        <>
                                        <div className='disp-block align-center' style={{margin:'20px 0px'}}>
                                            <span className='btn padd-5px font-very-small hover-opacity br' style={{backgroundColor:'#034000', color:'#eee'}} onClick={() => onClickBeginPromotion()}>Begin Promotion System <i className='fas fa-level-up-alt'></i></span>
                                        </div>
                                        </>
                                    )}
                                    {new Date('2024-12-07T14:50:10.894Z').getTime() > new Date().getTime() && (
                                        <>
                                        <div className='disp-block align-center' style={{margin:'20px 0px'}}>
                                            <span className='btn padd-5px font-very-small hover-opacity br' style={{backgroundColor:'#00057a', color:'#eee'}} onClick={() => onClickBeginReversePromotion()}>Begin Reverse Demotion System <i className='fas fa-level-up-alt'></i></span>
                                            <Note>This feature will expire by 7th December 2024</Note>
                                        </div>
                                        
                                        </>
                                    )}
                                    <Table>
                                        <Tr btn={false} header={true} sn={true} text={[{text:"Adm No."}, {text:"Name"}, {text:"Phone No."}, {text: 'Class'}, {text:"House"}, {text:"Status"}]} />
                                        {allStudentData.results.length === 0 ? (<Tr btn={false} text={[{text:noRecordFoundText(), style:{width:'100%'}}]} style={{textAlign:'center'}} styleText={{color:'red'}}/>) :        
                                            insertTableData()                      
                                        }
                                    </Table>
                                    <div  style={{marginTop:'40px', display:'flex', justifyContent:'center'}}>
                                        <Pagination count={getPageSize(allStudentData.count, tableState.size)} page={tableState.p} onChange={handleTablePagination} size="small" color="primary" variant="outlined" shape="rounded" />
                                    </div>
                                </Section>
                            </>
                            )}
                            </GridContent>
                        </Grid>
                    </>
                )}
                
            </ComponentWrapper>
        </>
    )

}


export default ManageStudent;