import http, {http_file, normal_http, http3, http4, http_file4} from "api/http_setup";

let student_url = 'voskool/student/app/self';
let parent_url = 'voskool/parent/app';
let staff_url = 'voskool/staff/app';
let game_url = 'game';

// for game services
export const gameServices = {
    getAllSubject : (auth_id=0) => {
        return http3(auth_id).get(`${game_url}/subject/all`);
    }
}
// for students school app
export const appServices2 = {
    getAccess: (id=0, sid=0) => {
        return http.get(`${student_url}/access/get/${id}/${sid}`);
    },
    getAccessB: (id=0) => {
        return http.get(`${student_url}/access/get/${id}`);
    },
    getAccessResult: (id) => {
        return http.get(`${student_url}/access/result/get/${id}`);
    },
    getAllResultKey: (id=0, sid=0) => {
        return http.get(`${student_url}/access/result/key/all/${id}/${sid}`);
    },
    getMinorResult: (id=0, ssid=0) => {
        return http.get(`${student_url}/self/access/result/m/get/${id}/${ssid}`);
    },
    generateStudentResultKey:(data={}) => {
        return http.post(`${student_url}/access/result_key/generate`, data);
    },
    getAccessPayment: (id=0, sess_id=0) => {
        return http.get(`${student_url}/access/payment/get/${id}/${sess_id}`)
    },
    updateAccess: (id=0, data) => {
        return http.put(`${student_url}/access/update/${id}`, data);
    },
    insertAccessSubject: data => {
        return http.post(`${student_url}/access/subject/insert`, data);
    },
    getNavData: (id) => {
        return http.get(`${student_url}/school_links/all/${id}`);
    }
}

export const appServices3 = {
    getAccess: (id=0, sid=0) => {
        return http.get(`${parent_url}/self/access/get/${id}/${sid}`);
    },
    getAccessB: (id=0) => {
        return http.get(`${parent_url}/self/access/get/${id}`);
    },
    getAllKidResult: (id=0, ssid=0) => {
        return http.get(`${parent_url}/self/access/result/m/all/${id}/${ssid}`);
    },
    // getAllKidResult: (id=0, ssid=0) => {
    //     return http.get(`${parent_url}/self/access/result/m/all/${id}/${ssid}`);
    // },
    getAllKidResultWithKey: (id=0, sid=0) => {
        return http.get(`${parent_url}/self/access/result/key/all/${id}/${sid}`);
    },
    updateParentAccessToApp:(id=0, sid=0) => {
        return http.put(`${parent_url}/self/kid_access/update/${id}/${sid}`);
    }
    // getParentStudentAccess: (id=0) => {
    //     return http3().get(`${parent_url}/self/access/get/${id}`);
    // }
}

export const appServices4 = {
    getAllSchoolStudent: (sid=0, sbid=0, cid="") => {
        return http.get(`${staff_url}/student/all/${sid}/${sbid}?cid=${cid}`);
    },

    initRemark: (sid=0, mark="") => {
        return http.get(`${staff_url}/init/remark/${sid}/${mark}`);
    },
    getAllSchoolPayment: (sid=0, stid=0, y='', m='') => {
        return http.get(`${staff_url}/self/payment/all/${sid}/${stid}?y=${y}&m=${m}`);
    },
    getAllCurrentSchoolPayment: (sid=0, stid=0) => {
        return http.get(`${staff_url}/self/payment/current/all/${sid}/${stid}`);
    },
    finalizeSchoolPayment: (id=0, data={}) => {
        return http.put(`${staff_url}/self/payment/finalize/${id}`, data);
    },

    getAccess: (id=0, sid=0) => {
        return http.get(`${staff_url}/self/access/get/${id}/${sid}`);
    },
    getAccessB: (id=0, sid=0) => {
        return http.get(`${staff_url}/self/access/get/${id}`);
    },
    getAllClassesThought: (stid=0, sid=0) => {
        return http.get(`${staff_url}/self/classes/all/${stid}/${sid}`);
    },
    getAllSubjectThought: (stid=0, csid="") => {
        return http.get(`${staff_url}/self/subject/all/${stid}?csid=${csid}`);
    },
    getAllSchoolStaffExamTimeTableSupervision: (sid=0, stid=0, csid="") => {
        return http.get(`${staff_url}/self/exam/subject_supervision/all/${sid}/${stid}?csid=${csid}`);
    },
    getAllSchoolStaffExamTimeTableSupervisionRevision: (sid=0, stid=0, csid="") => {
        return http.get(`${staff_url}/self/exam/subject_supervision/review/all/${sid}/${stid}?csid=${csid}`);
    },
    getAllSchoolStaffExamTimeTableSupervisionToday: (sid=0, stid=0) => {
        return http.get(`${staff_url}/self/exam/subject_supervision_today/all/${sid}/${stid}`);
    },
    getSchoolStaffExamTimeTableSupervisionToday: (pk=0, stid=0, sid=0) => {
        return http.get(`${staff_url}/self/exam/subject_supervision_today/get/${pk}/${stid}/${sid}`);
    },
    getSchoolStaffExamTimeTableSupervisionRevision: (pk=0, stid=0, sid=0) => {
        return http.get(`${staff_url}/self/exam/subject_supervision/review/get/${pk}/${stid}/${sid}`);
    },
    getAllSchoolStaffExamTimeTableSupervisionStudentToday: (sid=0, stid=0) => {
        return http.get(`${staff_url}/self/exam/subject_supervision_today/student/all/${sid}/${stid}`);
    },
    getAllSchoolStaffExamTimeTableSupervisionStudentReview: (sid=0, stid=0) => {
        return http.get(`${staff_url}/self/exam/subject_supervision/student/review/all/${sid}/${stid}`);
    },
    updateSchoolStaffExamTimeTableSupervisionStudentStatToday: (data={}, pk=0, sid=0, stid=0) => {
        return http.put(`${staff_url}/self/exam/subject_supervision_today/student/stat/update/${pk}/${sid}/${stid}`, data);
    },
    updateSchoolStaffExamTimeTableSupervisionStudentStatReview: (data={}, pk=0, sid=0, stid=0) => {
        return http.put(`${staff_url}/self/exam/subject_supervision_today/student/stat/review/update/${pk}/${sid}/${stid}`, data);
    },
    updateSchoolStaffExamTimeTableSubject: (data={}, pk=0, stid=0) => {
        return http.put(`${staff_url}/self/exam/subject_supervision_today/student/exam_timetable/update/${pk}/${stid}`, data);
    },
    updateSchoolStaffExamTimeTableSubjectReview: (data={}, pk=0, stid=0) => {
        return http.put(`${staff_url}/self/exam/subject_supervision_today/student/exam_timetable/review/update/${pk}/${stid}`, data);
    },
    getAllFormMasterClasses: (id=0) => {
        return http.get(`${staff_url}/self/form_master/classes/all/${id}`);
    },
    getAllSchoolMainStudentScoresEntryFormMaster: (stid=0, sid=0, sbid=0, cid=0, stuid=0) => {
        return http.get(`${staff_url}/self/scores_entry_main/form_master/student/all/${stid}/${sid}/${sbid}/${cid}/${stuid}`);
    },
    getSchoolStudentTraitScoreEntryFM: (stid=0, sid=0, sbid=0, cid=0, id=0) => {
        return http.get(`${staff_url}/self/scores_entry_main/form_master/student_trait/get/${stid}/${sid}/${sbid}/${cid}/${id}`);
    },
    getAllSchoolTempStudentSubjectScoresEntry: (subid=0, sid=0, sbid=0, cid=0) => {
        return http4(subid).get(`${staff_url}/self/scores_entry_temp/subject/student/all/${subid}/${cid}/${sid}/${sbid}`);
    },
    getAllSchoolMainStudentSubjectScoresEntry: (subid=0, sid=0, sbid=0, cid=0) => {
        return http4(subid).get(`${staff_url}/self/scores_entry_main/subject/student/all/${subid}/${cid}/${sid}/${sbid}`);
    },
    getSchoolMainStudentSubjectScoresEntry: (subid=0, id=0, sid=0, sbid=0) => {
        return http4(subid).get(`${staff_url}/self/scores_entry_main/subject/student/get/${id}/${sid}/${sbid}`);
    },
    insertSchoolStudentTraiScoretEntryFM: (data) => {
        return http.post(`${staff_url}/self/scores_entry_main/form_master/student_trait/insert`, data);
    },
    updateSchoolStudentTraitScoreEntryFM: (stid=0, sid=0, cid=0, id=0, data={}) => {
        return http.put(`${staff_url}/self/scores_entry_main/form_master/student_trait/update/${id}/${stid}/${sid}/${cid}`, data);
    },
    insertSchoolStudentScoreEntryTemp: (data, subid=0) => {
        return http4(subid).post(`${staff_url}/self/scores_entry_temp/student/insert`, data);
    },
    insertSchoolStudentScoreEntryMain: (data, subid=0) => {
        return http4(subid).post(`${staff_url}/self/scores_entry_main/student/insert`, data);
    },
    updateSchoolTempStudentScoresEntry: (subid=0, pk=0, sid=0, sbid=0, data={}) => {
        return http4(subid).put(`${staff_url}/self/scores_entry_temp/student/update/${pk}/${sid}/${sbid}`, data);
    },
    updateSchoolMainStudentScoresEntry: (subid=0, pk=0, sid=0, sbid=0, data={}) => {
        return http4(subid).put(`${staff_url}/self/scores_entry_main/student/update/${pk}/${sid}/${sbid}`, data);
    },

    updateSchoolStudentAttendance: (id=0, stuid=0, data) => {
        return http.put(`${staff_url}/attendance/student/days/update/${id}/${stuid}`, data);
    },
    getAllSchoolStudentAttendance: (stuid=0, sid=0, cid="", from="", to="") => {
        return http.get(`${staff_url}/attendance/student/days/all/${stuid}/${sid}?cid=${cid}&from=${from}&to=${to}`);
    },

    insertSchoolExamQuestionSubject: (data, subid=0) => {
        return http4(subid).post(`${staff_url}/exam/question/subject/insert`, data);
    },
    updateSchoolExamQuestionSubject: (id=0, stid=0, data, subid=0) => {
        return http4(subid).put(`${staff_url}/exam/question/subject/update/${id}/${stid}`, data);
    },
    getSchoolExamQuestionSubject1: (subid=0, cid=0, stid=0) => {
        return http4(subid).get(`${staff_url}/exam/question/subject/get/a/${subid}/${cid}/${stid}`);
    },
    insertSchoolExamMultiQuestionFig: (data, subid=0) => {
        return http_file4(subid).post(`${staff_url}/exam/question/fig/insert`, data);
    },
    getAllSchoolExamMultiQuestionFig: (esid=0, stid=0, subid=0) => {
        return http4(subid).get(`${staff_url}/exam/question/fig/all/${esid}/${stid}`);
    },
    deleteSchoolExamMultiQuestionFig: (pk=0, stid=0, subid=0) => {
        return http4(subid).delete(`${staff_url}/exam/question/fig/delete/${pk}/${stid}`);
    },
    insertSchoolExamQuestion: (data, subid=0) => {
        return http_file4(subid).post(`${staff_url}/exam/question/insert`, data);
    },
    updateSchoolExamQuestion: (id=0, data, subid=0, stid=0) => {
        return http_file4(subid).put(`${staff_url}/exam/question/update/${id}/${stid}`, data);
    },
    updateSchoolExamQuestionOrder: (id=0, stid=0, data, subid=0) => {
        return http4(subid).put(`${staff_url}/exam/question/update/order/${id}/${stid}`, data);
    },
    deleteSchoolExamQuestion: (id=0, stid=0, subid=0) => {
        return http4(subid).delete(`${staff_url}/exam/question/delete/${id}/${stid}`);
    },
    getAllSchoolExamQuestion: (esid=0, stid=0, etype="", subid) => {
        return http4(subid).get(`${staff_url}/exam/question/all/${esid}/${stid}?etype=${etype}`);
    },
    insertSchoolCurriculumTheme: (subid=0, data) => {
        return http4(subid).post(`${staff_url}/self/curriculum/theme/insert`, data);
    },
    updateSchoolCurriculumTheme: (subid=0, id=0, data) => {
        return http4(subid).put(`${staff_url}/self/curriculum/theme/update/${id}`, data);
    },
    getAllSchoolCurriculumTheme: (sid=0, cid=0, subid=0) => {
        return http.get(`${staff_url}/self/curriculum/theme/all/${sid}/${cid}/${subid}`);
    },
    deleteSchoolCurriculumTheme: (subid=0, id=0) => {
        return http4(subid).put(`${staff_url}/self/curriculum/theme/delete/${id}`);
    },
    insertSchoolCurriculumTopic: (subid=0, data) => {
        return http4(subid).post(`${staff_url}/self/curriculum/topic/insert`, data);
    },
    updateSchoolCurriculumTopic: (subid=0, id=0, data) => {
        return http4(subid).put(`${staff_url}/self/curriculum/topic/update/${id}`, data);
    },
    getAllSchoolCurriculumTopic: (theme_id=0) => {
        return http.get(`${staff_url}/self/curriculum/topic/all/${theme_id}`);
    },
    deleteSchoolCurriculumTopic: (subid=0, id=0) => {
        return http4(subid).put(`${staff_url}/self/curriculum/topic/delete/${id}`);
    },
    insertSchoolCurriculumContent: (subid=0, data) => {
        return http4(subid).post(`${staff_url}/self/curriculum/content/insert`, data);
    },
    updateSchoolCurriculumContent: (subid=0, id=0, data) => {
        return http4(subid).put(`${staff_url}/self/curriculum/content/update/${id}`, data);
    },
    deleteSchoolCurriculumContent: (subid=0, id=0) => {
        return http4(subid).delete(`${staff_url}/self/curriculum/content/delete/${id}`);
    },
}

export const appServices5 = {
    getAllSchoolSportHouse: (sid=0) => {
        return normal_http.get(`/voskool/app/school/sport/house/all_users/${sid}`);
    },
    getAllBanks: (country=0) => {
        return normal_http.get(`/voskool/banks/${country}`);
    },
    initializeStudentExamSitNo: (sid=0) => {
        return normal_http.get(`/voskool/app/school/sesn/init/${sid}`);
    },
    initializeStudentExamSitNoMain: (sid=0, ettid=0) => {
        return normal_http.get(`/voskool/app/school/sesn/init/main/${sid}/${ettid}`);
    },
    getAllSchoolSubject: (school_id=0, csid='', q="", order_by="") => {
        return http.get(`/voskool/app/subject/school/all/${school_id}?csid=${csid}&q=${q}&order_by=${order_by}`);
    },
    getAllSchoolSubjectMain: (school_id=0, csid='', q="", order_by="") => {
        return http.get(`/voskool/app/subject/school/all_main/${school_id}?csid=${csid}&q=${q}&order_by=${order_by}`);
    },
    getAllSessionM: (school_id=0, q="") => {
        return http.get(`/voskool/app/session/m/all/${school_id}?q=${q}`);
    },
    getCurrentSession: (school_id=0) => {
        return http.get(`/voskool/app/session/view_current/m/${school_id}`);
    },
    getAllClassSectionSchool: (school_id=0) => {
        return normal_http.get(`/voskool/app/class_section/school/all/${school_id}`);
    },
    getAllClassSchoolItem: (school_id=0) => {
        return normal_http.get(`/voskool/app/class/school/all_item/${school_id}`);
    },
    getAllSchoolScores: (sid=0) => {
        return http.get(`/voskool/app/school/scores/all/${sid}`);
    }
}


export const webAppServices = {
    startAppService: (data={}) => {
        return http.post(`/voskool/eapp/web/web_link/insert`, data); 
    },
    updateWebLink: (id=0, data={}) => {
        return http.put(`/voskool/eapp/web/web_link/update/${id}`, data); 
    },
    getAllWebLink: (sid=0) => {
        return http.get(`/voskool/eapp/web/web_link/all/${sid}`);
    },
    insertWeb: (data={}) => {
        return http.post(`/voskool/eapp/web/web/insert`, data); 
    },
    updateWeb: (id=0, data={}) => {
        return http.put(`/voskool/eapp/web/web/update/${id}`, data); 
    },
    pushWebLive: (sid=0, data={}) => {
        return http.put(`/voskool/eapp/web/push/${sid}`, data); 
    },
    getAllWeb: (sid=0, swid='') => {
        return http.get(`/voskool/eapp/web/web/all/${sid}?swid=${swid}`);
    },
}

// for admin school app and general endpoints
export const appServices = {
    insertSchoolBankAccount: (data={}) => {
        return http.post(`/voskool/bank/manage`, data);  
    },
    createSchoolVoSpace: (data={}) => {
        return http.post(`/voskool/vospace/school/create`, data);
    },
    createSchoolVoSpaceToken: (data={}) => {
        return http.post(`/voskool/vospace/token/create`, data);
    },
    createSchoolStudentVoSpacePayment: (data={}) => {
        return http.post(`/voskool/vospace/token/student/pay`, data);
    },
    createSchoolVoSpacePayment: (data={}) => {
        return http.post(`/voskool/vospace/school/payment/initialize`, data);
    },
    getSchoolVoSpacePayment: () => {
        return http.get(`/voskool/vospace/school/payment/initialize`);
    },
    makeSchoolVoSpacePayment: (data={}) => {
        return http.put(`/voskool/vospace/school/payment/initialize`, data);
    },
    getSchoolVoSpace: (id=0) => {
        return http.get(`/voskool/vospace/school/get/${id}`);
    },
    getAllVoSpacePlan: () => {
        return http.get(`/voskool/vospace/plan`);
    },
    getSchoolDashboard: (sid=0) => {
        return http.get(`/voskool/app/school/dashboard/${sid}`);
    },
    getSchoolBank: (sid=0) => {
        return http.get(`/voskool/bank/${sid}`);
    },
    actSchoolBankAccount: (id=0) => {
        return http.put(`/voskool/bank/act/${id}`, {});  
    },
    getAllSchoolBankAccount: (sid="") => {
        return http.get(`/voskool/bank/manage?sid=${sid}`);  
    },
    getNavData: (id) => {
       return http.get(`/voskool/school/nav/${id}`);
    },
    encryptData: (id) => {
        return normal_http.get(`/voskool/encrypt/${id}`);
    },
    insertParentByAdmin: (data={}) => {
        return http.post(`/voskool/app/school/adm/parent/insert`, data);  
    },
    getAllParentADMIN: (sid=0) => {
        return http.get(`/voskool/app/school/adm/parent/all/${sid}`);
    },
    getAllParentByADMIN: (sid=0) => {
        return http.get(`/voskool/app/school/by_adm/parent/all/${sid}`);
    },
    insertStudentByAdmin: (data={}) => {
        return http_file.post(`/voskool/app/school/adm/student/insert`, data);  
    },
    initRemark: (sid=0, mark="") => {
        return http.get(`/voskool/app/init/remark/${sid}/${mark}`);
    },
    getAllOccupations: () => {
        return normal_http.get(`/voskool/occupations`);
    },
    getAllGuardians: () => {
        return normal_http.get(`/voskool/guardian_types`);
    },
    getAllDisabilities: () => {
        return normal_http.get(`/voskool/disabilities`);
    },
    getAllLocations: () => {
        return normal_http.get(`/voskool/location_list`);
    },
    insertBookAuthor: data => {
        return http.post(`/voskool/book_author/insert`, data);
    },
    getAllBookAuthor: (q="", p=1, size=250) => {
        return http.get(`/voskool/book_author/all?p=${p}&size=${size}&q=${q}`);
    },
    insertBook: data => {
        return http.post(`/voskool/app/book/insert`, data);
    },
    updateBook: (id=0, data) => {
        return http.put(`/voskool/app/book/update/${id}`, data);
    },
    getAllBook: (q="", p=1, size=250) => {
        return http.get(`/voskool/app/book/all?q=${q}&p=${p}&size=${size}`);
    },
    getSchool: (title) => {
        return normal_http.get(`/voskool/school/data/${title}`);
    },
    insertTerm: data => {
        return http.post(`/voskool/app/term/insert`, data);
    },
    updateSchoolPic: (id=0, data={}) => {
        return http.put(`/voskool/update_registered_school_picture/${id}`, data);
    },
    updateSchoolM: (id=0, data={}) => {
        return http.put(`/voskool/update_school_m/${id}`, data);
    },
    getAllTerm: (school_id=0) => {
        return http.get(`/voskool/app/term/all/${school_id}`);
    },
    getTerm: (id=0, school_id=0) => {
        return http.get(`/voskool/app/term/${id}/${school_id}`);
    },
    updateTerm: (id=0, data) => {
        return http.put(`/voskool/app/term/data/${id}/update`, data);
    },
    deleteTerm: (id=0) => {
        const data = {active: false};
        return http.put(`/voskool/app/term/data/${id}/update`, data)
    },
    managePromotion: (sid=0) => {
        return http.put(`/voskool/app/promote_students/${sid}`, {});
    },
    onceReverseManagePromotion: (sid=0) => {
        return http.put(`/voskool/app/once_reverse_promote_students/${sid}`, {});
    },
    insertSession: data => {
        return http.post(`/voskool/app/session/insert`, data);
    },
    getAllSession: (school_id=0, q="", page=1) => {
        return http.get(`/voskool/app/session/view/${school_id}?p=${page}&size=250&q=${q}`);
    },
    
    getCurrentSession: (school_id=0) => {
        return http.get(`/voskool/app/session/view_current/${school_id}`);
    },
    updateSession: (school_id=0, id=0, data) => {
        return http.put(`/voskool/app/session/update/${school_id}/${id}`, data);
    },
    deleteSession: (id=0, type="") => {
        return http.delete(`/voskool/app/session/delete/${id}`);
    },
    updateSchoolSportHouse: (id=0, data) => {
        return http.put(`/voskool/app/school/sport/house/update/${id}`, data);
    },
    getAllSchoolSportHouse: (sid=0) => {
        return http.get(`/voskool/app/school/sport/house/all/${sid}`);
    },
    insertSchoolCurriculumTheme: data => {
        return http.post(`/voskool/app/school/curriculum/theme/insert`, data);
    },
    updateSchoolCurriculumTheme: (id=0, data) => {
        return http.put(`/voskool/app/school/curriculum/theme/update/${id}`, data);
    },
    getAllSchoolCurriculumTheme: (sid=0, cid=0, subid=0) => {
        return http.get(`/voskool/app/school/curriculum/theme/all/${sid}/${cid}/${subid}`);
    },
    deleteSchoolCurriculumTheme: (id=0) => {
        return http.put(`/voskool/app/school/curriculum/theme/delete/${id}`);
    },
    insertSchoolCurriculumTopic: data => {
        return http.post(`/voskool/app/school/curriculum/topic/insert`, data);
    },
    updateSchoolCurriculumTopic: (id=0, data) => {
        return http.put(`/voskool/app/school/curriculum/topic/update/${id}`, data);
    },
    getAllSchoolCurriculumTopic: (theme_id=0) => {
        return http.get(`/voskool/app/school/curriculum/topic/all/${theme_id}`);
    },
    deleteSchoolCurriculumTopic: (id=0) => {
        return http.put(`/voskool/app/school/curriculum/topic/delete/${id}`);
    },
    insertSchoolCurriculumContent: data => {
        return http.post(`/voskool/app/school/curriculum/content/insert`, data);
    },
    updateSchoolCurriculumContent: (id=0, data) => {
        return http.put(`/voskool/app/school/curriculum/content/update/${id}`, data);
    },
    deleteSchoolCurriculumContent: (id=0) => {
        return http.delete(`/voskool/app/school/curriculum/content/delete/${id}`);
    },
    insertSchoolBook: data => {
        return http_file.post(`/voskool/app/school/book/insert`, data);
    },
    updateSchoolBook: (id=0, data) => {
        return http.put(`/voskool/app/school/book/update/${id}`, data);
    },
    deleteSchoolBook: (id=0) => {
        return http.delete(`/voskool/app/school/book/delete/${id}`);
    },
    getAllSchoolBook: (sid=0, p=0, size=0,  q="", cid='', hlp="") => {
        return http.get(`/voskool/app/school/book/all/${sid}?p=${p}&size=${size}&q=${q}&cid=${cid}&hlp=${hlp}`);
    },
    insertSchoolBranchBook: data => {
        return http.post(`/voskool/app/school/book/school_branch/insert`, data);
    },
    insertStudentSchoolSubject: data => {
        return http.post(`/voskool/user/student/subject/insert`, data);
    },
    updateStudentSchoolSubject: data => {
        return http.put(`/voskool/user/student/subject/insert`, data);
    },
    updateStudentAccess: (id=0, data) => {
        return http.put(`/voskool/user/student/access/update/${id}`, data);
    },
    insertSchoolClassesBook: data => {
        return http.post(`/voskool/app/school/book/classes/insert`, data);
    },
    updateSchoolSchoolBranchBook: (id=0, data) => {
        return http.put(`/voskool/app/school/book/school_branch/update/${id}`, data);
    },
    deleteSchoolBookClasses: (id=0) => {
        return http.delete(`/voskool/app/school/book/classes/delete/${id}`);
    },
    updateSchoolFees: (id=0, data) => {
        return http.put(`/voskool/app/school/fees/update/${id}`, data);
    },
    getAllSchoolFees: (sbid=0) => {
        return http.get(`/voskool/app/school/fees/all/${sbid}`);
    },
    getAllSchoolFees_USERS: (sbid=0, type="", csid="") => {
        return normal_http.get(`/voskool/app/school/fees/all_users/${sbid}?type=${type}&csid=${csid}`);
    },
    insertSchoolScores: data => {
        return http.post(`/voskool/app/school/scores/insert`, data);
    },
    updateSchoolScores: (id=0, data) => {
        return http.put(`/voskool/app/school/scores/update/${id}`, data);
    },
    deleteSchoolScores: (id=0) => {
        return http.delete(`/voskool/app/school/scores/delete/${id}`);
    },
    getAllSchoolScores: (sid=0) => {
        return http.get(`/voskool/app/school/scores/all/${sid}`);
    },
    insertSchoolScoresGrade: data => {
        return http.post(`/voskool/app/school/scores/grade/insert`, data);
    },
    updateSchoolScoresGrade: (id=0, data) => {
        return http.put(`/voskool/app/school/scores/grade/update/${id}`, data);
    },
    deleteSchoolScoresGrade: (id=0) => {
        return http.delete(`/voskool/app/school/scores/grade/delete/${id}`);
    },
    getAllSchoolScoresGrade: (sid=0) => {
        return http.get(`/voskool/app/school/scores/grade/all/${sid}`);
    },
    createSchoolStudentAttendance: data => {
        return http.post(`/voskool/app/school/student/attendance/create`, data);
    },
    createSchoolStudentAttendanceDays: data => {
        return http.post(`/voskool/app/school/student/attendance/days/create`, data);
    },
    startStudentSchoolAttendanceSession: (id=0) => {
        return http.put(`/voskool/app/school/student/attendance/days/start/${id}`, {});
    },
    getSchoolAttendance: (sid=0, ssid=0) => {
        return http.get(`/voskool/app/school/attendance/get/${sid}/${ssid}`);
    },
    initSchoolStudentAttendance: (sid=0) => {
        return http.get(`/voskool/app/school/student/attendance/init_existing/${sid}`);
    },
    getAllSchoolStudentAttendance: (sid=0, ssid=0, sbid='', cid='', from="", to="") => {
        return http.get(`/voskool/app/school/attendance/student/days/all/${sid}/${ssid}?sbid=${sbid}&cid=${cid}&from=${from}&to=${to}`);
    },
    getSchoolStudentAttendanceAll: (ssid=0, stuid=0) => {
        return http.get(`/voskool/app/school/attendance/student/my/all/${ssid}/${stuid}`);
    },
    uploadSchoolExamTimetable: (id=0) => {
        return http.put(`/voskool/app/school/exam/timetable/upload/${id}`, {});
    },
    insertSchoolExamTimetable: data => {
        return http.post(`/voskool/app/school/exam/timetable/insert`, data);
    },
    deleteSchoolExamTimetable: (id=0) => {
        return http.delete(`/voskool/app/school/exam/timetable/delete/${id}`);
    },
    getAllSchoolExamTimetable: (sid=0, ssid=0, csid='') => {
        return http.get(`/voskool/app/school/exam/timetable/all/${sid}/${ssid}?csid=${csid}`);
    },
    insertSchoolExamTimetableTime: data => {
        return http.post(`/voskool/app/school/exam/timetable/time/insert`, data);
    },
    updateSchoolExamTimetableTime: (id=0, data) => {
        return http.put(`/voskool/app/school/exam/timetable/time/update/${id}`, data);
    },
    deleteSchoolExamTimetableTime: (id=0) => {
        return http.delete(`/voskool/app/school/exam/timetable/time/delete/${id}`);
    },
    getSchoolExamTimetableSubject: (ssid=0, subid=0) => {
        return http.get(`/voskool/app/school/exam/timetable/subject/get/${ssid}/${subid}`);
    },
    insertSchoolExamTimetableSubject: data => {
        return http.post(`/voskool/app/school/exam/timetable/subject/insert`, data);
    },
    updateSchoolExamTimetableSubject: (id=0, data) => {
        return http.put(`/voskool/app/school/exam/timetable/subject/update/${id}`, data);
    },
    deleteSchoolExamTimetableSubject: (id=0) => {
        return http.delete(`/voskool/app/school/exam/timetable/subject/delete/${id}`);
    },
    insertSchoolExamTimetableSubjectSupervisor: data => {
        return http.post(`/voskool/app/school/exam/timetable/subject/supervisor/insert`, data);
    },
    deleteSchoolExamTimetableSubjectSupervisor: (id=0) => {
        return http.delete(`/voskool/app/school/exam/timetable/subject/supervisor/delete/${id}`);
    },
    insertSchoolExamQuestionSubject: data => {
        return http.post(`/voskool/app/school/exam/question/subject/insert`, data);
    },
    updateSchoolExamQuestionSubject: (id=0, data) => {
        return http.put(`/voskool/app/school/exam/question/subject/update/${id}`, data);
    },
    getSchoolExamQuestionSubject1: (subid=0, ssid=0, cid=0) => {
        return http.get(`/voskool/app/school/exam/question/subject/get/a/${ssid}/${subid}/${cid}`);
    },
    insertSchoolExamMultiQuestionFig: data => {
        return http_file.post(`/voskool/app/school/exam/question/fig/insert`, data);
    },
    getAllSchoolExamMultiQuestionFig: (esid=0) => {
        return http.get(`/voskool/app/school/exam/question/fig/all/${esid}`);
    },
    deleteSchoolExamMultiQuestionFig: (pk=0) => {
        return http.delete(`/voskool/app/school/exam/question/fig/delete/${pk}`);
    },
    insertSchoolExamQuestion: data => {
        return http_file.post(`/voskool/app/school/exam/question/insert`, data);
    },
    updateSchoolExamQuestion: (id=0, data) => {
        return http_file.put(`/voskool/app/school/exam/question/update/${id}`, data);
    },
    updateSchoolExamQuestionOrder: (id=0, data) => {
        return http.put(`/voskool/app/school/exam/question/update/order/${id}`, data);
    },
    deleteSchoolExamQuestion: (id=0) => {
        return http.delete(`/voskool/app/school/exam/question/delete/${id}`);
    },
    getAllSchoolExamQuestion: (esid=0, etype="", scb="") => {
        return http.get(`/voskool/app/school/exam/question/all/${esid}?etype=${etype}&scb=${scb}`);
    },
    getAllSchoolExamQuestionPdfData: (esid=0, ssid=0, subid=0, ob="") => {
        return http.get(`/voskool/app/school/exam/question/all/pdf/${esid}/${ssid}/${subid}?ob=${ob}`);
    },
    startSchoolStaffPayment: (data={}) => {
        return http.post(`/voskool/staff/payment/start`, data);
    },
    paySchoolStaffPayment: (id=0, data={}) => {
        return http.put(`/voskool/staff/payment/pay/${id}`, data);
    },
    getAllSchoolStaffPayment: (sid=0, y=0, m=0) => {
        return http.get(`/voskool/staff/payment/all/${sid}?y=${y}&m=${m}`);
    },
    getAllPayment: (ssid=0, p=1, size=20, sbid='', cid='') => {
        return http.get(`/voskool/payment/all/${ssid}?sbid=${sbid}&cid=${cid}&p=${p}&size=${size}`);
    },
    getAllFinance: (sid=0) => {
        return http.get(`/voskool/finance/all/${sid}`);
    },
    getUnactivatedPaymentAccess: (enroll_id=0) => {
        return http.get(`/voskool/user/student/payment/unactivated_access/get/${enroll_id}`);
    },
    getAllBooks_FOR_PAYMENT: (sid=0, cid=0, sbid=0) => {
        return http.get(`/voskool/user/student/payment/books/all/${sid}/${cid}/${sbid}`);
    },
    insertSchoolStudentFreshPayment: data => {
        return http.post(`/voskool/user/student/payment/new/insert`, data);
    },
    insertSchoolStudentPaymentCart: data => {
        return http.post(`/voskool/user/student/payment/cart/insert`, data);
    },
    updateSchoolStudentPaymentCartQty: (id=0, user_id=0, data) => {
        return http.put(`/voskool/user/student/payment/cart/qty/update/${id}/${user_id}`, data);
    },
    processSchoolStudentPaymentCartCheckout: (id=0, user_id=0, type="") => {
        return http.put(`/voskool/user/student/payment/cart/process/checkout/${id}/${user_id}/${type}`, {});
    },
    processSchoolStudentPaymentCartPay: (id=0, data) => {
        return http.put(`/voskool/user/student/payment/cart/process/payment/${id}`, data);
    },
    deleteSchoolStudentPaymentCart: (id=0, user_id=0) => {
        return http.delete(`/voskool/user/student/payment/cart/delete/${id}/${user_id}`);
    },
    insertSchoolWeekDay: data => {
        return http.post(`/voskool/app/school/weekday/insert`, data);
    },
    updateSchoolWeekDay: (id=0) => {
        return http.put(`/voskool/app/school/weekday/update/${id}`);
    },
    getAllSchoolWeekDayData: (sid=0, q="") => {
        return http.get(`/voskool/app/school/weekday/all/${sid}?q=${q}`);
    },
    getAllSchoolTimeTableData: (sid=0, ssid=0, day_id=0, time_id=0) => {
        return http.get(`/voskool/app/school/timetable/all/${sid}/${ssid}/${day_id}/${time_id}`);
    },
    insertSchoolTimeTableTime: data => {
        return http.post(`/voskool/app/school/timetable_time/insert`, data);
    },
    updateSchoolTimeTableTime: (id=0, data) => {
        return http.put(`/voskool/app/school/timetable_time/update/${id}`, data);
    },
    getAllSchoolTimeTableTimeData: (sid=0, ssid=0) => {
        return http.get(`/voskool/app/school/timetable_time/all/${sid}/${ssid}`);
    },
    insertSchoolTimeTableSubject: data => {
        return http.post(`/voskool/app/school/timetable_subject/insert`, data);
    },
    updateSchoolTimeTableSubject: (id=0, data) => {
        return http.put(`/voskool/app/school/timetable_subject/update/${id}`, data);
    },
    deleteSchoolTimeTableSubject: (id=0) => {
        return http.delete(`/voskool/app/school/timetable_subject/delete/${id}`);
    },
    insertSchoolBranch: data => {
        return http.post(`/voskool/app/school_branch/insert`, data);
    },
    getAllSchoolBranch: (school_id=0) => {
        return http.get(`/voskool/app/school_branch/all/${school_id}`);
    },
    updateSchoolBranch: (id=0, data) => {
        return http.put(`/voskool/app/school_branch/update/${id}`, data);
    },
    deleteSchoolBranch: (id=0) => {
        const data= {
            is_active:false
        }
        return http.put(`/voskool/app/school_branch/update/${id}`, data);
    },
    insertSchoolUser: (user_name, type, data) => {
        return http.post(`/voskool/app/school_user/insert/${user_name}?q=${type}`, data);
    },
    updateSchoolUser: (id=0, data) => {
        return http.put(`/voskool/app/user_detail/update/${id}`, data);
    },
    inactivateSchoolUser: (id=0) => {
        const data = {is_school_active: 0}
        return http.put(`/voskool/app/user_detail/update/${id}`, data);
    },
    activateSchoolUser: (id=0) => {
        const data = {is_school_active: 1}
        return http.put(`/voskool/app/user_detail/update/${id}`, data);
    },
    insertParentData: (data) => {
        return http.post(`/voskool/user/parent/insert`, data);
    },
    getParentData: (id=0) => {
        return http.get(`/voskool/user/parent/get/${id}`);
    },
    insertStudentData: (data) => {
        return http_file.post(`/voskool/user/student/insert`, data);
    },
    getStudentData: (id=0) => {
        return http.get(`/voskool/user/student/get/${id}`);
    },
    insertStudentEnrolledData: (data) => {
        return http.post(`/voskool/user/student/enroll/insert`, data);
    },
    getStudentEnrolledData: (uid=0) => {
        return http.get(`/voskool/user/student/enroll/get/${uid}`);
    },
    getAllStudentEnrolledByParentData: (uid=0) => {
        return http.get(`/voskool/user/student/enroll/by_parent/all/${uid}`);
    },
    getStudentEnrolledAcceptedData: (uid=0, sid=0) => {
        return http.get(`/voskool/user/student/enroll/accepted/get/${uid}/${sid}`);
    },
    getAllStudentEnrolled_Admin: (sid=0, p=1, size=20, sbid="", q="") => {
        return http.get(`/voskool/user/student/admin/enroll/all/${sid}?sbid=${sbid}&q=${q}&p=${p}&size=${size}`);
    },
    proccessStudentEnrolled_Admin: (id=0, data={}) => {
        return http.put(`/voskool/user/student/admin/enroll/process/${id}`, data);
    },
    terminateStudentEnrolled_Admin: (id=0) => {
        return http.put(`/voskool/user/student/admin/enroll/terminate/${id}`);
    },
    getStudentEnrolled_Admin: (id=0) => {
        return http.get(`/voskool/user/student/admin/enroll/get/${id}`);
    },
    getAllStudent_Admin : (sid=0, sbid=0, q="", class_id="", p=1, size=20) => {
        return http.get(`/voskool/user/student/admin/all/${sid}/${sbid}?q=${q}&class=${class_id}&p=${p}&size=${size}`);
    },
    getAllStudent_MinorAdmin : (sid=0, sbid=0, q="", class_id="", isa="") => {
        return http.get(`/voskool/user/student/admin/minor/all/${sid}/${sbid}?q=${q}&class=${class_id}&isa=${isa}`);
    },
    getAllStudentSessResult_MinorAdmin : (sid=0, sbid=0, sess_id="", class_id="") => {
        return http.get(`/voskool/user/student_past_res/admin/minor/all/${sid}/${sbid}/${sess_id}/${class_id}`);
    },
    uploadStudentResult: (id=0, data={}) => {
        return http.put(`/voskool/user/student/result/upload/${id}`, data);
    },
    getStudentResult: (ssid=0, stuid=0, cid=0) => {
        return http.get(`/voskool/user/student/check_result/get/${ssid}/${stuid}/${cid}`);
    },
    getStudentResultStatus: (id=0, ssid=0) => {
        return http.get(`/voskool/app/school/sesn/status/init/${id}/${ssid}`);
    },
    getStudentSchoolAccess_Admin : (uid=0, sid=0) => {
        return http.get(`/voskool/user/student/admin/get/${uid}/${sid}`);
    },
    getAllStudentSubject_Admin : (uid=0, sid=0) => {
        return http.get(`/voskool/user/student/subject/admin/all/${uid}/${sid}`);
    },
    getAllStudentSubjectAdded_Admin : (uid=0, sid=0) => {
        return http.get(`/voskool/user/student/subject/admin/all_added/${uid}/${sid}`);
    },
    getAllStaffSchoolTypeData: (pd) => {
        return http.get(`/voskool/user/staff/school/type/all/${pd}`);
    },
    getStaffSchoolTypeData: (id=0) => {
        return http.get(`/voskool/user/staff/school/type/get/${id}`);
    },
    insertStaffSchool: (data) => {
        return http_file.post(`/voskool/user/staff/school/insert`, data)
    },
    getStaffSchool: (id=0) => {
        return http.get(`/voskool/user/staff/school/get/${id}`);
    },
    insertStaffSchoolCV: (data) => {
        return http_file.post(`/voskool/user/staff/school/cv/insert`, data)
    },
    getAllStaffSchoolCVData: (sd=0) => {
        return http.get(`/voskool/user/staff/school/cv/all/${sd}`);
    },
    getStaffSchoolCV: (id=0) => {
        return http.get(`/voskool/user/staff/school/cv/get/${id}`);
    },
    deleteStaffSchoolCV: (id=0) => {
        return http.delete(`/voskool/user/staff/school/cv/delete/${id}`);
    },
    insertStaffSchoolApplication: (data) => {
        return http.post(`/voskool/user/staff/school/application/insert`, data)
    },
    updateStaffSchoolApplication: (id=0, data) => {
        return http.put(`/voskool/user/staff/school/application/update/${id}`, data);
    },
    updateStaffSchoolApplicationSalary: (id=0, sid=0, data) => {
        return http.put(`/voskool/user/staff/school/application/salary/update/${id}/${sid}`, data);
    },
    getAllStaffSchoolApplication: (sid=0, p=0, size=0,  q="", sbid=0, filter_by="") => {
        return http.get(`/voskool/user/staff/school/application/all/${sid}?p=${p}&size=${size}&q=${q}&sbid=${sbid}&filter_by=${filter_by}`);
    },
    getAllStaffSchoolStaffApplication: (id=0, vid=0) => {
        return http.get(`/voskool/user/staff/school/application/staff/all/${id}/${vid}`);
    },
    rejectStaffSchoolApplication: (id=0) => {
        return http.put(`/voskool/user/staff/school/application/reject/${id}`, {});
    },
    handleStaffSchoolAccess: (id=0, q=0) => {
        return http.put(`/voskool/user/staff/school/access/iupd/${id}/${q}`, {});
    },
    getAllStaffSchoolAccess: (sbid=0, p=0, size=0,  q="", order_by="", sid="") => {
        return http.get(`/voskool/user/staff/school/access/all/${sbid}?p=${p}&size=${size}&q=${q}&order_by=${order_by}&sid=${sid}`);
    },
    getAllStaffSchoolAccessMinorData: (sbid=0, sid="") => {
        return http.get(`/voskool/user/staff/school/access/all/m/${sbid}?sid=${sid}`);
    },
    getStaffSchoolAccess: (id=0) => {
        return http.get(`/voskool/user/staff/school/access/get/${id}`);
    },
    updateStaffSchoolAccessPerm: (id=0, data={}) => {
        return http.put(`/voskool/user/staff/school/access/perm/update/${id}`, data);
    },
    getStaffSchoolAccessPerm: (id=0) => {
        return http.get(`/voskool/user/staff/school/access/perm/get/${id}`);
    },
    getAllStaffSchoolSubject: (csid=0, type="") => {
        return http.get(`/voskool/user/staff/school/subject_handler/all/${csid}?type=${type}`);
    },
    getAllStaffSchoolClassesSubject: (subid=0, type="") => {
        return http.get(`/voskool/user/staff/school/subject_class_handler/all/${subid}?type=${type}`);
    },
    insertStaffSchoolSubject: ( data) => {
        return http.post(`/voskool/user/staff/school/subject/insert`, data);
    },
    manageStaffSchoolFormMaster: (stid=0, sid=0) => {
        return http.put(`/voskool/user/staff/school/access/form_master/add/${stid}/${sid}`);
    },
    updateStaffSchoolFormMaster: (id=0, data={}) => {
        return http.put(`/voskool/user/staff/school/access/form_master/update/${id}`, data);
    },
    getAllStaffSchoolFormMaster: (stid=0, sid=0) => {
        return http.get(`/voskool/user/staff/school/access/form_master/all/${stid}/${sid}`);
    },
    getAllStaffSchoolSubjectAssigned: (stid=0, csid="", cid="") => {
        return http.get(`/voskool/user/staff/school/subject/all/${stid}?csid=${csid}&cid=${cid}`);
    },
    deleteStaffSchoolSubjectAssigned: (id=0) => {
        return http.put(`/voskool/user/staff/school/subject/delete/${id}`);
    },
    getStaffSchoolApplication: (id=0) => {
        return http.get(`/voskool/user/staff/school/application/get/${id}`);
    },
    getSchoolStaffSchoolApplication: (sid=0, id=0) => {
        return http.get(`/voskool/user/staff/school/application/get/${sid}/${id}`);
    },
    insertStaffSchoolSalary: (data) => {
        return http.post(`/voskool/user/staff/school/type/salary/insert`, data)
    },
    updateStaffSchoolSalary: (id=0, data) => {
        return http.put(`/voskool/user/staff/school/type/salary/update/${id}`, data);
    },
    deleteStaffSchoolSalary: (id=0) => {
        return http.delete(`/voskool/user/staff/school/type/salary/delete/${id}`);
    },
    getAllStaffSchoolSalary: (sid=0, sbid=0, stft=0) => {
        return http.get(`/voskool/user/staff/school/type/salary/all/${sid}?sbid=${sbid}&stft=${stft}`);
    },
    getStaffSchoolSalary: (id=0) => {
        return http.get(`/voskool/user/staff/school/type/salary/get/${id}`);
    },
    getAllUser: (school_id=0, type, access="", p=1, size=20, status=1, q="", order_by="") => {
        return http.get(`/voskool/app/user/all/${school_id}?p=${p}&size=${size}&type=${type}&access=${access}&status=${status}&q=${q}&order_by=${order_by}`);
    },
    getUser: (id=0, school_id=0, user_id=0) => {
        return http.get(`/voskool/app/user/me/${school_id}/${user_id}/${id}`);
    },
    getUserA: (user_id=0) => {
        return http.get(`/voskool/app/user/me/a/${user_id}`);
    },
    updateUser: (id=0, data) => {
        return http.put(`/voskool/app/user/update/${id}`, data);
    },
    updateUserProfile: (id="", data) => {
        return http_file.put(`/voskool/app/user/update_profile/${id}`, data);
    },
    checkUserOnline: (id=0) => {
        const time = new Date().getTime() + 120000;
        const data = {is_online:time}
        return http.put(`/voskool/app/user/check_online/${id}`, data);
    },
    getOnlineUser: (id=0) => {
        return http.get(`/voskool/app/user/get_online/${id}`);
    },
    updateUserAct: (id=0) => {
        const data = {is_active:1}
        return http.put(`/voskool/app/user/check_online/${id}`, data);
    },
    insertClassSection: ( data) => {
        return http.post(`/voskool/app/class_section/insert`, data);
    },
    insertClassSectionSchool: ( data) => {
        return http.post(`/voskool/app/class_section/school/insert`, data);
    },
    updateClassSectionSchool: (id=0, data) => {
        return http.put(`/voskool/app/class_section/school/update/${id}`, data);
    },
    updateClassSectionPicSchool: (id=0, data) => {
        return http.put(`/voskool/app/class_section/pic/school/update/${id}`, data);
    },
    updateClassSection: (id=0, data) => {
        return http.put(`/voskool/app/class_section/update/${id}`, data);
    },
    deleteClassSection: (id=0, type="") => {
        var data = {};
        if(type==="undo"){
            data = { is_active: true}
            return http.put(`/voskool/app/class_section/delete/${id}`, data); 
        }
        else{
            data = {  is_active: false }
            return http.put(`/voskool/app/class_section/delete/${id}`, data);
        }
    },
    deleteClassSectionSchool: (id=0) => {
        return http.delete(`/voskool/app/class_section/school/delete/${id}`);
    },
    getAllClassSection: (school_id=0) => {
        return normal_http.get(`/voskool/app/class_section/all/${school_id}`);
    },
    getAllClassSectionSchool: (school_id=0) => {
        return normal_http.get(`/voskool/app/class_section/school/all/${school_id}`);
    },
    getClassSection: (id=0) => {
        return normal_http.get(`/voskool/app/class_section/view/${id}`);
    },
    getClassSectionSchool: (id=0) => {
        return http.get(`/voskool/app/class_section/school/view/${id}`);
    },
    getClassSectionSchool2: (school_id=0, csid=0) => {
        return http.get(`/voskool/app/class_section/school/get/${school_id}/${csid}`);
    },
    insertClass: ( data) => {
        return normal_http.post(`/voskool/app/class/insert`, data);
    },
    insertClassSchool: ( data) => {
        return http.post(`/voskool/app/class/school/insert`, data);
    },
    updateClass: (id=0, data) => {
        return http.put(`/voskool/app/class/update/${id}`, data);
    },
    updateClassSchool: (id=0, data) => {
        return http.put(`/voskool/app/class/school/update/${id}`, data);
    },
    deleteClass: (id=0, type="") => {
        var data = {};
        if(type==="undo"){
            data = { is_active: true}
            return http.put(`/voskool/app/class/delete/${id}`, data); 
        }
        else{
            data = {  is_active: false }
            return http.put(`/voskool/app/class/delete/${id}`, data);
        }
    },
    deleteClassSchool: (id=0) => {
        return http.delete(`/voskool/app/class/school/delete/${id}`);
    },
    getAllClass: (school_id=0, class_section_id=0) => {
        return http.get(`/voskool/app/class/all/${school_id}/${class_section_id}`);
    },
    getAllClassSchool: (school_id=0, class_section_id=0) => {
        return http.get(`/voskool/app/class/school/all/${school_id}/${class_section_id}`);
    },
    getAllClassItem: (school_id=0) => {
        return http.get(`/voskool/app/class/all_item/${school_id}`);
    },
    getAllClassSchoolItem: (school_id=0) => {
        return http.get(`/voskool/app/class/school/all_item/${school_id}`);
    },
    getClass: (id=0) => {
        return normal_http.get(`/voskool/app/class/view/${id}`);
    },
    getClassSchool: (id=0) => {
        return http.get(`/voskool/app/class/school/view/${id}`);
    },
    getClassSchool2: (sid=0, cid=0) => {
        return http.get(`/voskool/app/class/school/view2/${sid}/${cid}`);
    },
    insertClassBlock: ( data) => {
        return http.post(`/voskool/app/class_block/insert`, data);
    },
    updateClassBlock: (id=0, data) => {
        return http.put(`/voskool/app/class_block/update/${id}`, data);
    },
    deleteClassBlock: (id=0) => {
        return http.delete(`/voskool/app/class_block/delete/${id}`); 
    },
    getAllClassBlock: (sid=0, cid="", csid="") => {
        return http.get(`/voskool/app/class_block/all/${sid}?csid=${csid}&cid=${cid}`);
    },
    insertSubjectgroup: ( data) => {
        return http.post(`/voskool/app/subject_group/insert`, data);
    },
    getAllSubjectGroup: (school_id=0) => {
        return http.get(`/voskool/app/subject_group/all/${school_id}`);
    },
    insertSubject: ( data) => {
        return http.post(`/voskool/app/subject/insert`, data);
    },
    updateSubject: (id=0, data) => {
        return http.put(`/voskool/app/subject/update/${id}`, data);
    },
    deleteSubject: (id=0, type="") => {
        var data = {};
        if(type==="undo"){
            data = { is_active: true}
            return http.put(`/voskool/app/subject/delete/${id}`, data); 
        }
        else{
            data = {  is_active: false }
            return http.put(`/voskool/app/subject/delete/${id}`, data);
        }
    },
    getAllSubject: (school_id=0, csid='', q="") => {
        return http.get(`/voskool/app/subject/all/${school_id}?csid=${csid}&q=${q}`);
    },
    insertSubjectSchool: ( data) => {
        return http.post(`/voskool/app/subject/school/insert`, data);
    },
    insertSubjectStaffAssignSchool: ( data) => {
        return http.post(`/voskool/app/subject/school/staff_assign/insert`, data);
    },
    deleteSubjectStaffAssignSchool: (id="") => {
        return http.delete(`/voskool/app/subject/school/staff_assign/delete/${id}`);
    },
    insertSubjectStaffClassAssignSchool: ( data) => {
        return http.post(`/voskool/app/subject/school/staff/class_assign/insert`, data);
    },
    deleteSubjectStaffClassAssignSchool: (id="") => {
        return http.delete(`/voskool/app/subject/school/staff/class_assign/delete/${id}`);
    },
    
    updateSubjectSchool: (id=0, data) => {
        return http.put(`/voskool/app/subject/school/update/${id}`, data);
    },
    deleteSubjectSchool: (id=0, type="") => {
        var data = {};
        if(type==="undo"){
            data = { is_active: true}
            return http.put(`/voskool/app/subject/school/delete/${id}`, data); 
        }
        else{
            data = {  is_active: false }
            return http.put(`/voskool/app/subject/school/delete/${id}`, data);
        }
    },
    getAllSubjectSchool: (school_id=0, csid='', q="", order_by="") => {
        return http.get(`/voskool/app/subject/school/all/${school_id}?csid=${csid}&q=${q}&order_by=${order_by}`);
    },
    getAllSubjectGenre: () => {
        return http.get(`/voskool/app/subject_genre/all`);
    },
    
    
}