import React, { useState, useEffect, useMemo, Fragment} from 'react';
// import { Link } from 'react-router-dom';

import Section from "components/app/school/SmallerComponents/Section";
import Mallet from 'components/app/school/SmallerComponents/mallet';
import View from 'components/app/school/SmallerComponents/mallet/view';
import Notification from "components/app/school/SmallerComponents/notification";

import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";

import { appServices, appServices5 } from 'api/schoolApp/services';
import { addItemToArray } from 'components/app/school/actions/array-utils/Add';
import { updateArray, updateArrayWithArray } from 'components/app/school/actions/array-utils/Update';
import Spinner from 'components/spinner';
import Loader from 'components/app/school/SmallerComponents/loader';

const AddSubjectStudent = ({ uid, school_id, school_title, data}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);

    const [error, setError] = useState(initialErrorState);
    const [success, setSuccess] = useState(initialSuccessState);
    const [warning] = useState(initialSuccessState);

    const [allSubject, setAllSubject] = useState([]);
    const [allSubjectOffered, setAllSubjectOffered] = useState([]);

    const [genreType, setGenreType] = useState('');

    const [preloading, setPreloading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(true);
    const [isGenreCompulsory, setIsGenreCompulsory] = useState(false);
    const [isShowSaveBtn, setIsShowSaveBtn] = useState(true);

    const fetchAllSubject = (sid=0, csid="", uid) => {
        if(sid && uid){
            setError({data:[], title:''});
            setPreloading(true);
            appServices5.getAllSchoolSubjectMain(sid, csid).then(res => {
                appServices.getAllStudentSubjectAdded_Admin(uid, sid).then(res2 => {
                    const subjects = addItemToArray(res.data, {is_checked:false, is_main:false, is_changed:false, is_active:false});
                    let upd = subjects;
                    if(res2.data.length > 0){
                        const res2d = addItemToArray(res2.data, {is_main:true, is_changed:false}, 'is_checked', 'is_active');
                        upd = addItemToArray(updateArrayWithArray(subjects, res2d, 'id', 'subject'));
                    }
                    setAllSubject(upd);
                    const filter = res.data.filter((it) => it.genre_name === "science" || it.genre_name === "art");
                    setPreloading(false);
                    setLoading(false);
                    if(filter.length > 0){
                        setIsGenreCompulsory(true);
                    }
                }).catch(e => {
                    const subjects = addItemToArray(res.data, {is_checked:false, is_main:false, is_changed:false});
                    setAllSubject(subjects);
                    const filter = res.data.filter((it) => it.genre_name === "science" || it.genre_name === "art");
                    setPreloading(false);
                    setLoading(false);
                    if(filter.length > 0){
                        setIsGenreCompulsory(true);
                    }
                })
            }).catch(e => {
                setError({data:[e.response.data.detail ? e.response.data.detail : 'Internal Server Error'], title:'Subject Data Fetch Error', click_text:'Reload', handleClick: () => setReload(true)});
                setAllSubject([]);
                setPreloading(false);
                setLoading(false);
            })
        }
    }
    const fetchAllSubjectOffered = (uid="", sid=0) => {
        if(uid && sid){
            appServices.getAllStudentSubject_Admin(uid, sid).then(res => {
                setAllSubjectOffered(res.data);
            }).catch(e => {
                setAllSubjectOffered([]);
            })
        }
    }
    
    const onClickSubjectBox = (checked=false, num=-1, item={}) => {
        setError(initialErrorState);
        setIsShowSaveBtn(true);
        if(item.genre_name === "religion" && checked){
            const filterReligionalItem = allSubject.filter((item) => item.genre_name === "religion" && item.is_checked);
            if(!filterReligionalItem.length){
                setAllSubject(updateArray(allSubject, num, {...item, is_checked:checked, is_changed:true}));
                return;
            }
            setError({data:['Only one religious subject is required'], title:'Error'});
            return;
        }
        setAllSubject(updateArray(allSubject, num, {...item, is_checked:checked}));
    }
    const onChangeGenre = (e) => {
        setGenreType(e.target.value);
        setIsShowSaveBtn(true);
        const val = e.target.value;
        if(val === "all"){
            const filter = allSubject.filter((it) => it.genre_name === "science" || it.genre_name === "art");
            if(filter.length > 0){
                let filter_arr = [];
                filter.forEach((it) => filter_arr.push({...it, is_checked:true}));
                setAllSubject(updateArrayWithArray(allSubject, filter_arr, 'num', 'num'));
                
            }
            return;
        }
        if(val){
            const filter = allSubject.filter((it) => it.genre_name === val);
            const oppGenre = val === "science" ? "art" : 'science';
            const filterOpp = allSubject.filter((it) => it.genre_name === oppGenre);
            if(filter.length > 0){
                let filter_arr = [];
                let filter_opp_arr = [];
                filter.forEach((it) => filter_arr.push({...it, is_checked:true}));
                filterOpp.forEach((it) => filter_opp_arr.push({...it, is_checked:false}));
                const combine = filter_arr.concat(filter_opp_arr)
                setAllSubject(updateArrayWithArray(allSubject, combine, 'num', 'num'));
                filterOpp.forEach((it) => setAllSubject(updateArray(allSubject, it.num, {...it, is_checked:false})));
            }
            return;
        }
        const filter = allSubject.filter((it) => it.genre_name === "science" || it.genre_name === "art");
        if(filter.length > 0){
            let filter_arr = [];
            filter.forEach((it) => filter_arr.push({...it, is_checked:false}));
            setAllSubject(updateArrayWithArray(allSubject, filter_arr, 'num', 'num'));
            return;
        }
    }

    const onClickFinish = () => {
        const filter = allSubject;
        const filterReligionalItem = allSubject.filter((item) => item.genre_name === "religion" && item.is_checked);
        var promise = Promise.resolve()
        setSuccess(initialSuccessState);
        setError(initialErrorState);
        if(isGenreCompulsory && !genreType){
            setError({data:['Genre Type Required'], title:'Error'});
            return;
        }
        if(filter.length > 0){
            if(filterReligionalItem.length === 1){
                const lastItemNum = filter.length - 1;
                const upd = (id='') => {
                    appServices.updateStudentAccess(id, {is_assigned_subjects:true, is_setup_app_account:true}).then(res => {
                        setLoading(false);
                        setSuccess({text:'All Subjects Added Successfully', title:'Reloading....'});
                        setIsShowSaveBtn(false);
                        fetchAllSubject(school_id, data.classes.class_section_school.id, uid);
                        // setTimeout(() => {
                        //     window.location.reload();
                        // }, 400);
                        
                    }).catch(e => {
                        fetchAllSubject(school_id, data.classes.class_section_school.id, uid);
                        setError({title:'Subject Insert Error', data:[e.response.data.detail ? e.response.data.detail :'Something went wrong']})
                        setLoading(false);
                    })
                }
                filter.forEach((item, i) => {
                    setLoading(true);
                    promise = promise.then(() => {
                        if(!item.is_main){
                            if(item.is_checked || item.genre_name === "general"){
                                const dt = {
                                    access: data.id,
                                    subject: item.id
                                }
                                appServices.insertStudentSchoolSubject(dt).then(res => {
                                    if(i === lastItemNum){
                                        upd(data.uid);
                                        return;
                                    }
                                    return new Promise((resolve) => setTimeout(resolve, 50));
                                }).catch(e => {
                                    setError({title:'Subject Insert Error', data:[e.response.data.detail ? e.response.data.detail :'Something went wrong']})
                                    if(i === lastItemNum){
                                        if(e.response.data.detail.toString().toLowerCase() === "data already exist"){
                                            upd(data.uid);
                                        }
                                        return;
                                    }
                                    return new Promise((resolve) => setTimeout(resolve, 50));
                                })
                            }
                            
                            
                        } else {
                            const dt = {
                                access: data.id,
                                subject: item.subject,
                                is_active: item.genre_name === "general" ? true : item.is_checked,
                            }
                            appServices.updateStudentSchoolSubject(dt).then(res => {
                                if(i === lastItemNum){
                                    fetchAllSubject(school_id, data.classes.class_section_school.id, uid);
                                    setIsShowSaveBtn(false);
                                    return;
                                }
                                return new Promise((resolve) => setTimeout(resolve, 50));
                            }).catch(e => {
                                setError({title:'Subject Insert Error', data:[e.response.data.detail ? e.response.data.detail :'Something went wrong']})
                                if(i === lastItemNum){
                                    fetchAllSubject(school_id, data.classes.class_section_school.id, uid);
                                    setIsShowSaveBtn(false);
                                    return;
                                }
                                return new Promise((resolve) => setTimeout(resolve, 50));
                            })
                        }
                        
                    })
                })
                return;
            }
            setError({data:['One religious subject is required'], title:'Error'});
            return;
        }
        setError({data:['Data Error'], title:'Error'});
    }

    useEffect(() => {
        if(school_id && reload && data){
            fetchAllSubject(school_id, data.classes.class_section_school.id, uid);
            fetchAllSubjectOffered(uid, school_id);
            setReload(false);
            return;
        }
    }, [school_id, data, reload, uid]);

    const artScienceSelect = (sci=[], art=[]) => {
        if(sci.length > 0 || art.length > 0){
            return(
                <>
                <div className='flex-100'>
                    <label className='font-very-small form-group '>Are you a/an .... </label>
                    <select className='flex-100 form-control-theme font-very-small app-text-color' name="genre" onChange={e => onChangeGenre(e)} value={genreType}>
                        <option value={''}>???</option>
                        {sci.length > 0 && <option value={'science'}>Science Student</option>}
                        {art.length > 0 && <option value={'art'}>Art Student</option>}
                        <option value={'all'}>All</option>
                    </select>
                </div>
                </>
            )
        }
    }

    const filterGeneralItem = allSubject.filter((item) => item.genre_name === "general");
    const filterReligionalItem = allSubject.filter((item) => item.genre_name === "religion");
    const filterOptionalItem = allSubject.filter((item) => item.genre_name === "optional");
    const filterScienceItem = allSubject.filter((item) => item.genre_name === "science");
    const filterArtItem = allSubject.filter((item) => item.genre_name === "art");
    const filterBothItem = allSubject.filter((item) => item.genre_name === "science" ||  item.genre_name === "art");

    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} click_text={error.click_text} handleClick={error.handleClick} />)
    })

    // console.log(allSubject, allSubjectOffered);

    return(
        <>
        {loading && <Loader />}
        <Section>
            <NotificationWrapper>
                {error.title && (errorHandler)}
                {warning.id && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)}
                {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
            </NotificationWrapper> 
            <span className="font-med fw-700 disp-block app-text-color">Student's Subjects</span><br />
            {!preloading  ? (
                <>
                    <Mallet >
                    {filterGeneralItem.length > 0 && (
                        <>
                        <div className='flex align-center' style={{paddingBottom:'2px'}}>
                            <span className="font-small fw-700 disp-block padd-4px app-text-sec-color">General Subjects</span>
                        </div>
                        {filterGeneralItem.map((item, i) => {
                            return(
                                <Fragment key={i}>
                                    <div  className='flex-33'>
                                        <div className={``} style={{paddingRight:'5px'}}>
                                            <span className='fw-500 font-slightly-small text-capitalize'>{item.name} <input type={"checkbox"} defaultChecked disabled /> <span className='red fw-600 font-super-small'>Click save to add subject</span></span>
                                        </div>
                                    </div>
                                </Fragment>
                            )
                        })}
                        </>
                    )}
                    {artScienceSelect(filterScienceItem, filterArtItem)}
                        {genreType === "science" && (
                            <>
                            {filterScienceItem.length > 0 && (
                                <>
                                <div className='flex align-center' style={{paddingBottom:'2px'}}>
                                    <span className="font-small fw-700 disp-block padd-4px app-text-sec-color">Science Subjects</span>
                                </div>
                                {filterScienceItem.map((item, i) => {
                                    return(
                                        <Fragment key={i}>
                                            <div  className='flex-33'>
                                                <div className={``}  style={{paddingRight:'5px'}}>
                                                    <span className='fw-500 font-slightly-small text-capitalize'>{item.name} <input type={"checkbox"} defaultChecked disabled /></span>
                                                </div>
                                            </div>
                                        </Fragment>
                                    )
                                })}
                                </>
                            )}
                            </>
                        )}
                        {genreType === "art" && (
                            <>
                            {filterArtItem.length > 0 && (
                                <>
                                <div className='flex align-center' style={{paddingBottom:'2px'}}>
                                    <span className="font-small fw-700 disp-block padd-4px app-text-sec-color">Art Subjects</span>
                                </div>
                                {filterArtItem.map((item, i) => {
                                    return(
                                        <Fragment key={i}>
                                            <div  className='flex-33'>
                                                <div className={``}  style={{paddingRight:'5px'}}>
                                                    <span className='fw-500 font-slightly-small text-capitalize'>{item.name} <input type={"checkbox"} defaultChecked disabled /></span>
                                                </div>
                                            </div>
                                        </Fragment>
                                    )
                                })}
                                </>
                            )}
                            </>
                        )}
                        {genreType === "all" && (
                            <>
                            {filterBothItem.length > 0 && (
                                <>
                                <div className='flex align-center' style={{paddingBottom:'2px'}}>
                                    <span className="font-small fw-700 disp-block padd-4px app-text-sec-color">All</span>
                                </div>
                                {filterBothItem.map((item, i) => {
                                    return(
                                        <Fragment key={i}>
                                            <div  className='flex-33'>
                                                <div className={``} style={{paddingRight:'5px'}}>
                                                    <span className='fw-500 font-slightly-small text-capitalize'>{item.name} <input type={"checkbox"} defaultChecked disabled /></span>
                                                </div>
                                            </div>
                                        </Fragment>
                                    )
                                })}
                                </>
                            )}
                            </>
                        )}
                        {filterReligionalItem.length > 0 && (
                            <>
                                <div className='flex align-center' style={{paddingBottom:'2px'}}>
                                    <span className="font-small fw-700 disp-block padd-4px app-text-sec-color">Religious Subjects</span>
                                </div>
                                {filterReligionalItem.map((item, i) => {
                                    return(
                                        <Fragment key={i}>
                                            <div  className='flex-33'>
                                                <div className={``}  style={{paddingRight:'5px'}}>
                                                    <span className='fw-500 font-slightly-small text-capitalize'>{item.name} <input type={"checkbox"} onChange={(e) => onClickSubjectBox(!item.is_checked, item.num, {...item})} checked={item.is_checked} /></span>
                                                </div>
                                            </div>
                                        </Fragment>
                                    )
                                })}
                            </>
                        )}
                        {filterOptionalItem.length > 0 && (
                            <>
                                <div className='flex align-center' style={{paddingBottom:'2px'}}>
                                    <span className="font-small fw-700 disp-block padd-4px app-text-sec-color">Optional Subjects</span>
                                </div>
                                {filterOptionalItem.map((item, i) => {
                                    return(
                                        <Fragment key={i}>
                                            <div  className='flex-33'>
                                                <div className={``} style={{paddingRight:'5px'}}>
                                                    <span className='fw-500 font-slightly-small text-capitalize'>{item.name} <input type={"checkbox"} name="optional" onChange={(e) => onClickSubjectBox(!item.is_checked, item.num, {...item})} checked={item.is_checked} /></span>
                                                </div>
                                            </div>
                                        </Fragment>
                                    )
                                })}
                            </>
                        )}
                    
                   
                </Mallet>
                {isShowSaveBtn && (
                    <div className='disp-block align-center'>
                        <button type="button" className='btn hover-opacity br-10 app-bg-color app-bg-text-color' onClick={() => onClickFinish()}>Save <i className='fas fa-paper-plane'></i></button>
                    </div>
                )}
                {!isShowSaveBtn && (
                    <Mallet>
                        <View flex='100' type="text" label="Subject(s) Currently Offering.">{allSubjectOffered.map((item, i) => {
                            return(
                                <span key={i} className='fw-500 text-capitalize app-text-color font-very-small'>{item.name}, </span>
                            )
                        })}<span className='fw-600 disp-block text-capitalize app-text-color font-very-small' style={{paddingTop:'2px'}}>Total: {allSubjectOffered.length} </span>
                        </View>
                    </Mallet>
                )}
                
                </>
            ) : (
                <>
                <div className='disp-block align-center'>
                    <Spinner />
                </div>
                
                </>
            )} 
            
            
        </Section>
        </>
    );
}

export default AddSubjectStudent;

